import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
import endPoint from 'config/urls';
import { Table } from 'antd';
import { Pagination } from 'antd';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import { accProps, eventProps } from '../interfaces';
import { parseYearDate } from '../AccountingPeriodController';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import './AllPeriod.styles.scss';
import { sampleProps } from '../AccountingPeriodController';


const AllPeriod = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [yearLength, setYearLength] = useState<number>(10)
    const [accPeriodData, setAccPeriodData] = useState< []>([]);

    useEffect(() => {
        getAccPeriods();
    }, [])


    const goToCreatePeriod = () => {
        navigate('/CreateNewPeriod');
    };

    const columns1 = [
        { title: 'Period', dataIndex: 'Period', key: 'Period' },
        { title: 'Description', dataIndex: 'Data', key: 'Data' },
        { title: 'From Date', dataIndex: 'FromDate', key: 'FromDate' },
        { title: 'To Date', dataIndex: 'ToDate', key: 'ToDate' },
        { title: 'Status', dataIndex: 'Status', key: 'Status' },
    ]

    const allAccountingPeriods = [
        {
            title: 'Accounting Period',
            dataIndex: 'FinYear',
            key: 'FinYear',
            align: 'left'
        },
        {
            title: 'Financial Period',
            dataIndex: 'FinPeriod',
            key: 'FinPeriod',
            align: 'left'
        },

        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: () => <a><u>Modify</u></a>,
            align: 'right',
        },
    ];

    const getAccPeriods = () => {
        const yeararr:accProps[] = [];
        const currentYear = parseYearDate(new Date());
        for (let j = 0; j < yearLength; j++) {
            const FinancialYear = Number(currentYear) - j;
            console.log("this is financial year", FinancialYear);
            const newArr: accProps[] = [];

                const startUrl = `https://general.sonic.dev.coherent.com.hk/general/v2/finance/v1/accountingperiod/${FinancialYear}/-1`;
                console.log("api URL", startUrl);
                if (startUrl) {
                    setIsLoading(true);
                    try {
                        fetch(`${startUrl}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            }
                        })
                            .then((response: any) => {
                                const statusCode = response.status;
                                const data = response.json();
                                return Promise.all([statusCode, data]);
                            })
                            .then(([statusCode, data]) => {
                                setIsLoading(false);
                                console.log("== step 1==");
                                if (statusCode === 200) {
                                    newArr.push(data);
                                    console.log("== step 2==",data);
                                    const yearObj = {
                                        key: j,
                                        FinYear: FinancialYear,
                                        description: <a>
                                            <Table columns={columns1} dataSource={newArr} pagination={false} />
                                        </a>
                                    }
                                    console.log("== step 3==", yearObj);
                                    yeararr.push(yearObj);
                                    if (j === yearLength - 1) {
                                        console.log("== final step == ");
                                        setAccPeriodData(yeararr);
                                    }
                                }
                            });
                    } catch (exception) {
                        setIsLoading(false);
                        console.log(exception);
                    }
                } else {
                    console.log('Search value is empty');
                }

                
        }

        
    }

    const data = accPeriodData;

    console.log("this is table data", accPeriodData);


    /*
    2021
     1
     2
    2020
     1
     2
     ..
    2019
     1
     2
     ..
    
    last 10 year
    for (2021 - 2011) {

    }

    */



    

console.log("this is table data", accPeriodData);

    return (
        <div id="all-period-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Finance</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Accounts</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-nolink">CreateNewAccount</li>
                        </ul>
                    </div>
                    <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                        <div className="m-ap-btn plush" onClick={goToCreatePeriod}>Create New</div>
                    </div>
                </div>

                <div className="m-ap-table-box">
                    <div className="m-ap-table">

                        <Table
                            columns={allAccountingPeriods}
                            expandable={{
                                expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                            }}
                            dataSource={accPeriodData}
                        />

                    </div>
                    {/* <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                        <Pagination defaultCurrent={1} total={50} />
                    </div> */}
                </div>



            </div>
        </div>
    )
}

export default AllPeriod;