import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContractList.css';
// import { BenefitsList } from './BenefitList';
import { RequestHeader } from './PrepareData';
// import { useGlobalState, apiRequest } from "store";
// import { policyByPhone, policyClearData, IPolicyList, setPhone, setPolicy } from 'store/actions/PolicyAction';

const ContractList = () => {
    interface IProps {
        Reg: string;
        Name: string;
        Polno: string;
        Email: string;
        Status?: string;
        Benefits?: object;
        Members?: object[];
    };

    let formData = {
        search_value: "",
        search_by: ""
    };

    // const [{ policyId, data, fetching }] = useGlobalState('PolicyReducer');
    const [showContList, setShowContList] = useState<IProps[]>([]);

    const newPolicy = () => {
        navigate('/newPolicy');
    };

    const getList = (SearchPolicyId = 'CCA-GRP-00129') => {
        // console.log('this is from store', policyId, data);
        // const phone = '4353534354';
        // apiRequest(setPhone(phone));
        const url = `https://group.sonic.dev.coherent.com.hk/enquiry/v1/group/id/${SearchPolicyId}`;
        //@ts-ignore
        fetch(url, {
            method: 'GET',
            headers: RequestHeader
        })
            .then((response: any) => response.json())
            .then((response: any) => {
                console.log(response);
                const list = [...showContList];
                list.push(response);
                setShowContList(list);
                setPolicyData(list);
            });
    }

    useEffect(() => {
        getList();
    }, []);

    // @ts-ignore
    const setPolicyData = (policyList: IProps[]) => {
        // @ts-ignore
        console.log('this is window', window.GroupPolicy);
        // @ts-ignore
        if (!window.GroupPolicy.isDataSet) {
            // @ts-ignore
            window.GroupPolicy.PolicyList = policyList;
            // @ts-ignore
            window.GroupPolicy.isDataSet = true;
        } else {
            console.log('here');
            // @ts-ignore
            window.GroupPolicy.PolicyList = policyList;
        }
        // window.GroupPolicy.CurrentPolicy = {}
    }

    const handleUpdate = (e: any) => {
        const { name, value } = e.target;
        // @ts-ignore
        formData[name] = value;
    }

    const searchPolicy = () => {
        getList(formData.search_value);
    }

    // const handleSearch = (e: any) => {
    //     const { value } = e.target;
    //     const cntList = apiRes.filter(cnt => (cnt.Reg.includes(value)
    //         || cnt.Name.includes(value) || cnt.Polno.includes(value)
    //         || cnt.Email.includes(value)));
    //     setShowContList(cntList);
    // }

    const goToPolicy = (pId: string) => {
        // @ts-ignore
        window.GroupPolicy.CurrentPolicy = window.GroupPolicy.PolicyList.filter(p => pId === p.MasterPolicyID)[0];
        navigate('/policyConfirmation');
    }

    return (
        <div id="contract-list-container">
            <div className="page">
                <div className="form">
                    <div className="form-group clear">
                        <div className="col-md-12">
                            <span id="SME">
                                SME Insurance Pre-Sales
                        </span>
                            <button className="button" onClick={newPolicy}>+Add New Policy</button><br /><br />
                        </div>
                    </div>
                    <div className="form-group search-container">
                        <p>&nbsp;</p>
                        <input type="text" className="search" name="search_value" id="search_value" placeholder="Search for a policy" onChange={(e) => handleUpdate(e)} />
                        &nbsp;
                    {/* <button className="dropdown">Search By</button> */}
                        <select className="form-control dropdown" name="search_by" id="search_by" onChange={(e) => searchPolicy()}>
                            <option value="">Search By</option>
                            <option value="Receipt_Number">Policy ID</option>
                        </select>
                    </div>

                </div>

                <div className="panel">
                    <div id="panel-heading">Master Contracts</div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" className="pad-left-th">Reg No</th>
                                <th scope="col">Owner/Name</th>
                                <th scope="col">Policy No</th>
                                {/* <th scope="col">Total Members</th> */}
                                <th scope="col">Last Updates</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                showContList.map(cnt => (
                                    // @ts-ignore
                                    <tr key={cnt.EntityInfo.EntityName}>
                                        <td className="pad-left" onClick={() => {
                                            // @ts-ignore
                                            return goToPolicy(cnt.MasterPolicyID)
                                        }
                                        }>
                                            {
                                                // @ts-ignore
                                                cnt.EntityInfo.IncNumber || cnt.MasterPolicyID
                                            }
                                        </td>
                                        <td>
                                            {
                                                // @ts-ignore
                                                cnt.EntityInfo.EntityName
                                            }
                                        </td>
                                        <td>
                                            {
                                                // @ts-ignore
                                                cnt.MasterPolicyID
                                            }
                                        </td>
                                        {/* <td>{cnt.MemCnt}</td> */}
                                        <td>
                                            {
                                                // @ts-ignore
                                                cnt.StartDate
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ContractList;
