import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../Receipt/ReceiptHeader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { Table } from 'antd';

const UserData = () => {
    // const [receiptsList, setReceiptsList] = useState<any[]>([]);

    // const goToReceiptDetail = () => {
    //     navigate('/ViewReceipt');
    // };

    useEffect(() => {
        console.log('this is useEffect - triggers when component renders')
    }, []);

    const columns = [
        {
          title: 'Full Name',
          width: 100,
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
        },
        {
          title: 'Age',
          width: 100,
          dataIndex: 'age',
          key: 'age',
          fixed: 'left',
        },
        {
          title: 'Column 1',
          dataIndex: 'address',
          key: '1',
          width: 150,
        },
        {
          title: 'Column 2',
          dataIndex: 'address',
          key: '2',
          width: 150,
        },
        {
          title: 'Column 3',
          dataIndex: 'address',
          key: '3',
          width: 150,
        },
        {
          title: 'Column 4',
          dataIndex: 'address',
          key: '4',
          width: 150,
        },
        {
          title: 'Column 5',
          dataIndex: 'address',
          key: '5',
          width: 150,
        },
        {
          title: 'Column 6',
          dataIndex: 'address',
          key: '6',
          width: 150,
        },
        {
          title: 'Column 7',
          dataIndex: 'address',
          key: '7',
          width: 150,
        },
        { title: 'Column 8', dataIndex: 'address', key: '8' },
        {
          title: 'Action',
          key: 'operation',
          fixed: 'right',
          width: 100,
          render: () => <a>action</a>,
        },
      ];
      
      const data = [];
      for (let i = 0; i < 100; i++) {
        data.push({
          key: i,
          name: `Edrward ${i}`,
          age: 32,
          address: `London Park no. ${i}`,
        });
      }


    return (
        <div id="all-receipts-container">
            <ReceiptHeader />
            <section>
                <div className="content-container">
                    <div className="content-title">
                        <p>User Data 
                            {/* <button id="receipt-search" className="btn btn-outline-dark float-right">+Create New</button> */}
                            </p>
                    </div>
                    <br />
                    <br /><br />

                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: 1500 }}
                        sticky
                    />

                    <div className="content-box payee-details">
                        <p className="title"></p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-2 mb-2 float-left">
                                    <label>Search Receipt By</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Begin your search" />
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>
                
            </section>
        </div>
    )
}

export default UserData;
