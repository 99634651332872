import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { navigate } from 'raviger';

import { NaviContainer, ChildrenSpan } from 'layout/PrivateLayout/styles';

export interface ILink {
  title?: string;
  icon?: string;
  path: string;
  children?: any;
}

interface IProps {
  path: string;
  paths: Array<ILink>;
  isCollapse: boolean;
}

const Navigation: React.FC<IProps> = ({ path, paths }) => {
  const [selected, setSelected] = useState<string>('');
  const [links, setLinks] = useState<Array<ILink>>([]);
  useEffect(() => {
    let routes: Array<ILink> = [];
    paths.forEach(route => route.icon && routes.push(route));
    setLinks(routes);
  }, [paths]);

  useEffect(() => {
    if (paths.length > 0) {
      const link = paths.find(pLink => pLink.path === path);
      setSelected(link ? link.path : '/dashboard');
    }
  }, [path, paths]);

  return (
    <NaviContainer
      mode="inline"
      defaultSelectedKeys={[selected]}
      selectedKeys={[selected]}
      onClick={(item: any) => navigate(item.key)}
    >
      {links.map((link, index) => (
        <Menu.Item key={`${link.path}`} title={link.title}>
          {
            link.children ? (
              <ChildrenSpan key={index}>
                <span>{link.title}</span>
              </ChildrenSpan>
            ) : (
              <>
                <i className={`zmdi zmdi-${link.icon}`} />
                <span>{link.title}</span>
              </>
            )
          }
        </Menu.Item>
      ))}
    </NaviContainer>
  );
};

export default Navigation;
