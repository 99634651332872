import React, { useEffect, useState } from 'react';
import { useRoutes, usePath, navigate } from 'raviger';

import Page404 from 'layout/Page404';
import Login from 'Pages/Public/Login';
import Logout from 'Pages/Public/Logout';
import Dashboard from 'Pages/Dashboard';
import Policy from 'Pages/Policy';
import Claims from 'Pages/Claims';
import ContractList from './Pages/Agent';
import AddNewPolicy from './Pages/Agent/AddNewPolicy'
import AddBenefits from './Pages/Agent/AddBenefits';
import AddMember from './Pages/Agent/AddMember';
import PolicyConfirmation from './Pages/Agent/PolicyConfirmation';
import AllReceipts from './Pages/Receipt/AccReceivable/AllReceipts';
import CreateReceipt from './Pages/Receipt/AccReceivable/CreateReceipt';
import ModifyReceipt from './Pages/Receipt/AccReceivable/ModifyReceipt';
import Review from './Pages/Receipt/AccReceivable/Review';
import Review2 from './Pages/Receipt/AccReceivable/Review2';
import PrintCheque from './Pages/Receipt/AccPayable/PrintCheque';
import PrintCheque2 from './Pages/Receipt/AccPayable/PrintCheque2';
import ViewReceipt from './Pages/Receipt/AccReceivable/ViewReceipt';
import ApplyPremium from './Pages/Receipt/AccReceivable/ApplyPremium';
import ApplyPremium123 from './Pages/Receipt/AccReceivable/ApplyPremium123';
import ApplyPremium4 from './Pages/Receipt/AccReceivable/ApplyPremium4';
import ApplyPremium57 from './Pages/Receipt/AccReceivable/ApplyPremium57';
import ApplyPremium8 from './Pages/Receipt/AccReceivable/ApplyPremium8';
import CreatePaymentVoucher2 from './Pages/Receipt/AccPayable/CreatePaymentVoucher2';
import CreateReceiptVoucher from './Pages/Receipt/AccPayable/CreateReceiptVoucher';
import ModifyVoucher from './Pages/Receipt/AccPayable/ModifyVoucher';
import ModifyVoucher2 from './Pages/Receipt/AccPayable/ModifyVoucher2';
import ModifyVoucher3 from './Pages/Receipt/AccPayable/ModifyVoucher3';
import BankTransfer1 from './Pages/Receipt/AccPayable/BankTransfer';
import BankTransfer2 from './Pages/Receipt/AccPayable/BankTransferPreviewList';
import AllVouchers from './Pages/Receipt/AccPayable/AllVouchers';
import ChequeManage from './Pages/Receipt/AccPayable/Cheque';
import ChequeManage2 from './Pages/Receipt/AccPayable/Cheque2';
import ChequeManage3 from './Pages/Receipt/AccPayable/Cheque3';

import AllJournals from './Pages/ManualJournal/AllJournal/AllJournals.component';
import CreateManualJournal from './Pages/ManualJournal/CreateJournal/CreateManualJournal';
import JournalReceipt from './Pages/ManualJournal/ViewJournal/ViewJournalReceipt';
import UserData from './Pages/UserData/';
import PrivateLayout, { IPrivateLayout } from 'layout/PrivateLayout';
import { ILink } from 'layout/PrivateLayout/Navigation';
import Preminumpage1 from './Pages/Receipt/AccReceivable/ApplyPremiumfinal';
import EnquireReceipt from './Pages/Receipt/AccReceivable/EnquireReceipt';

import AllAccounts from './Pages/Accounts/AllAccounts/AllAccounts';
import CreateNewAccount from './Pages/Accounts/CreateNewAccounts/CreateNewAccount';

import UploadFile from './Pages/FileUpload/Upload File/UploadFile.component';
import AllUpload from './Pages/FileUpload/AllUploads/AllUpload.component';

import Reports from './Pages/Report/ReportHome/Reports.component';
import ViewAccounts from 'Pages/Accounts/ViewAccounts.tsx/ViewAccounts.component';

import NoPeriod from './Pages/Accounting Period/NoPeriod/NoPeriod';
import AllPeriod from './Pages/Accounting Period/AllPeriod/AllPeriod.component';
import AllPeriodcopy from './Pages/Accounting Period/AllPeriod/AllPeriodcopy.component';
import CreateNewPeriod from './Pages/Accounting Period/CreateNewPeriod/CreateNewPeriod.component.';
import NoLedger from 'Pages/Ledger/NoLedger/NoLedger';
import AllLedger from 'Pages/Ledger/AllLedger/AllLedger.component';
import CreateLedger from 'Pages/Ledger/CreateLedger/CreateLedger';
import { Upload } from 'antd';

interface IRoute extends ILink {
  component: any;
  public?: boolean;
  parentPath?: string;
}

const Router = () => {
  const path = usePath();
  const [user, setUser] = useState();

  useEffect(() => {
    const checkLogin = async () => {
      // @TODO Already logged-in
      const session = true;
      setUser(session);
      !session && navigate('/login');
    };

    checkLogin();
  }, [path]);

  useEffect(() => {
    if (user && path === '/') {
      navigate('/login');
    }
  }, [user, path]);

  const authenticate = (Component: any, params: IPrivateLayout) => {
    if (user) {
      return (
        <PrivateLayout title={params.title} path={params.path}>
          <Component {...params} />
        </PrivateLayout>
      );
    }
  };

  const getRoutes = () => {
    let routeMap: any = {};
    routes.forEach(
      (route: IRoute) =>
      (routeMap[route.path] = (params: any) =>
        route.public ? (
          <route.component />
        ) : (
          authenticate(route.component, {
            ...params,
            title: route.title,
            path: route.parentPath || route.path
          })
        ))
    );

    return routeMap;
  };

  return useRoutes(getRoutes());
};

export const routes: Array<IRoute> = [
  {
    path: '/dashboard',
    icon: 'widgets',
    title: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/policy',
    icon: 'dot-circle-alt',
    title: 'Policy 360',
    component: Policy
  },
  {
    path: '/underwriting',
    icon: 'assignment-o',
    title: 'Underwriting',
    component: Page404
  },
  {
    path: '/claims',
    icon: 'receipt',
    title: 'Claims',
    component: Claims
  },
  {
    path: '/Reports',
    icon: 'trending-up',
    title: 'Reports',
    component: Reports
  },
  {
    path: '/finance',
    icon: 'trending-up',
    title: 'Finance',
    component: Page404
  },
  {
    path: '/AllAccounts',
    icon: 'trending-up',
    title: 'Accounts',
    component: AllAccounts
  },
  {
    path: '/AllAccounts',
    icon: 'trending-up',
    title: 'All Accounts',
    component: AllAccounts,
    children: true
  },
  {
    path: '/CreateNewAccount',
    icon: 'trending-up',
    title: 'Create New Account',
    component:CreateNewAccount,
    children: true
  },
  {
    path: '/ViewAccounts',
    icon: 'trending-up',
    title: 'ViewAccount',
    component:ViewAccounts,
    children: true
  },
  // {
  //   path: '/DepositeReceiptHome',
  //   icon: 'trending-up',
  //   title: 'Acct. Receivable',
  //   component: AllReceipts
  // },
  // {
  //   path: '/AllReceipts',
  //   icon: 'trending-up',
  //   title: 'All Receipts',
  //   component: AllReceipts,
  //   children: true
  // },

  // {
  //   path: '/DepositeReceipt',
  //   icon: 'trending-up',
  //   title: 'Deposit Receipt',
  //   component: CreateReceipt,
  //   children: true
  // },

  // {
  //   path: '/ModifyReceipt',
  //   icon: 'trending-up',
  //   title: 'Modify Receipt',
  //   component: ModifyReceipt,
  //   children: true
  // },
  // {
  //   path: '/ReviewReceipts',
  //   icon: 'trending-up',
  //   title: 'Review/Confirm/Cancel',
  //   component: Review,
  //   children: true
  // },
  // {
  //   path: '/ReviewChangeStatus',
  //   component: Review2
  // },
  // {
  //   path: '/ApplyPremium',
  //   icon: 'trending-up',
  //   title: 'Apply Premium',
  //   component: Preminumpage1,
  //   children: true
  // },
  // {
  //   path: '/ApplyPremiumReceipts',
  //   component: ApplyPremium4
  // },
  // {
  //   path: '/ApplyPremium57',
  //   component: ApplyPremium57
  // },

  // {
  //   path: '/EnquireReceipt',
  //   icon: 'trending-up',
  //   title: 'Enquire Receipt',
  //   component: EnquireReceipt,
  //   children: true
  // },

  // {
  //   path: '/AllVouchers1',
  //   icon: 'trending-up',
  //   title: 'Acct. Payable',
  //   component: AllVouchers
  // },

  // {
  //   path: '/AllVouchers',
  //   icon: 'trending-up',
  //   title: 'All Vouchers',
  //   component: AllVouchers,
  //   children: true
  // },
  // {
  //   path: '/CreateReceiptVoucher',
  //   icon: 'trending-up',
  //   title: 'Create Payment Voucher',
  //   component: CreateReceiptVoucher,
  //   children: true
  // },
  // {
  //   path: '/CreatePaymentVoucher2',
  //   component: CreatePaymentVoucher2,
  // },
  // {
  //   path: '/ModifyVoucher',
  //   icon: 'trending-up',
  //   title: 'Modify Voucher',
  //   component: ModifyVoucher,
  //   children: true
  // },
  // {
  //   path: '/ModifyVoucher2',
  //   component: ModifyVoucher2
  // },
  // {
  //   path: '/ModifyVoucher3',
  //   component: ModifyVoucher3
  // },
  // {
  //   path: '/Cheque',
  //   icon: 'trending-upcheque',
  //   title: 'Cheque Management',
  //   component: ChequeManage,
  //   children: true
  // },
  // {
  //   path: '/Cheque2',
  //   component: ChequeManage2,
  //   children: true
  // },
  // {
  //   path: '/Cheque3',
  //   component: ChequeManage3,
  //   children: true
  // },
  // {
  //   path: '/BankTransfer',
  //   icon: 'trending-up',
  //   title: 'Bank Transfer',
  //   component: BankTransfer1,
  //   children: true
  // },
  // {
  //   path: '/BankTransferPreviewList',
  //   component: BankTransfer2,
  //   children: true
  // },
  // {
  //   path: '/Cheque3',
  //   component: ChequeManage3,
  //   children: true
  // },

  // {
  //   path: '/PrintCheque',
  //   icon: 'trending-up',
  //   title: 'PrintCheque',
  //   component: PrintCheque,
  //   children: true
  // },
  // {
  //   path: '/PrintChequeReviewList',
  //   component: PrintCheque2,
  //   children: true
  // },

  {
    path: '/AllJournals',
    icon: 'trending-up',
    title: 'Manual journal',
    component: AllJournals
  },
  {
    path: '/AllJournals',
    icon: 'trending-up',
    title: 'All Journals',
    component: AllJournals,
    children: true
  },
  {
    path: '/CreateManualJournal',
    icon: 'trending-up',
    title: 'Create Journal',
    component: CreateManualJournal,
    children: true
  },
  
  {
    path: '/ViewJournalReceipt',
    component: JournalReceipt,
  },

  {
    path: '/ViewReceipt',
    component: ViewReceipt
  },
  {
    path: '/CreateNewPeriod',
    icon: 'trending-up',
    title: 'Accounting Period',
    component: CreateNewPeriod
  },
  {
    path: '/NoPeriod',
    icon: 'trending-up',
    title: 'No Periods',
    component: NoPeriod,
    children: true
  },
  {
    path: '/AllPeriod',
    icon: 'trending-up',
    title: 'All Periods',
    component: AllPeriod,
    children: true
  },
  {
    path: '/CreateNewPeriod',
    icon: 'trending-up',
    title: 'Create New Periods',
    component: CreateNewPeriod,
    children: true
  },
 
  {
    path: '/AllLedger',
    icon: 'trending-up',
    title: 'Ledger',
    component: AllLedger
  },
  {
    path: '/NoLedger',
    icon: 'trending-up',
    title: 'No Ledger',
    component: NoLedger,
    children: true
  },
  {
    path: '/AllLedger',
    icon: 'trending-up',
    title: 'All Ledgers',
    component: AllLedger,
    children: true
  },

  {
    path: '/CreateLedger',
    icon: 'trending-up',
    title: 'Create New Ledger',
    component: CreateLedger,
    children: true
  },
  {
    path: '/UploadFile',
    icon: 'receipt',
    title: 'Upload File',
    component: UploadFile
  },
  {
    path: '/UploadFile',
    icon: 'receipts',
    title: 'Upload File',
    component: UploadFile,
    children:true
  },
  {
    path: '/AllUpload',
    icon: 'receipts',
    title: 'All Uploads',
    component: AllUpload,
    children:true
  },
  {
    path: '/',
    icon: 'trending-up',
    title: 'PO',
    component: ContractList
  },
  {
    path: '/agentHome',
    icon: 'trending-up',
    title: 'Group SME',
    component: ContractList
  },
  {
    path: '/newPolicy',
    icon: 'trending-up',
    title: 'Add New Policy',
    component: AddNewPolicy,
    children: true
  },
  {
    path: '/agent',
    icon: 'trending-up',
    title: 'Enquire Policy',
    component: ContractList,
    children: true
  },
  {
    path: '/userData',
    icon: 'trending-up',
    title: 'User Data',
    component: UserData
  },
  {
    path: '/addBenefits',
    component: AddBenefits
  },
  {
    path: '/addMember',
    component: AddMember
  },
  {
    path: '/policyConfirmation',
    component: PolicyConfirmation
  },
  {
    path: '/profile',
    component: Page404
  },
  {
    path: '/settings',
    component: Page404
  },
  {
    path: '/login',
    public: true,
    component: Login
  },
  {
    path: '/logout',
    public: true,
    component: Logout
  }
];

export default Router;
