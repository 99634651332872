import React, { useEffect, useState, useContext, useRef } from 'react';
import {Input, Popconfirm, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { Table, Tag, Space } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AllUpload.styles.scss';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { info } from 'console';
import { fileURLToPath } from 'url';
// import reqwest from 'reqwest';
const AllUpload = () => {
    interface eventList {
        name: string;
        value: string;
    }
    interface eventProps {
        target: eventList
    }
    const today = new Date();
    const dummyData = [
        {key: 1,filename: 'xyz.csv',type: 'TPA Claim Service fee file',post: '2020-04-21',status: 'Uploaded',delete: <a>Delete</a>},
        {key: 2,filename: 'File name will appear here',type: 'TPA Claim Benefit Billing file',post: '2020-04-21',status: 'Uploaded',delete: <a>Delete</a>,},
        {key: 3,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 4,filename: 'File name will appear here',type: 'Billing file',post: '2020-04-21',status: 'Uploaded',delete: <a>Delete</a>,},
        {key: 5,filename: 'File name will appear here',type: 'Billing file',post: '2020-04-21',status: 'Uploaded',delete: <a>Delete</a>,},
        {key: 6,filename: 'File name will appear here',type: 'TPA Claim Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 7,filename: 'File name will appear here',type: 'TPA Claim Benefit Billing file',post: '2020-04-21',status: 'Approved',delete: <a>Delete</a>,},
        {key: 8,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Uploaded',delete: <a>Delete</a>,},
        {key: 9,filename: 'File name will appear here',type: 'Billing file',post: '2020-04-21',status: 'Uploaded',delete: <a>Delete</a>,},
        {key: 10,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 11,filename: 'File name will appear here',type: 'Billing file',post: '2020-04-21',status: 'Uploaded',delete: <a>Delete</a>,},
        {key: 12,filename: 'File name will appear here',type: 'TPA Claim Service fee file',post: '2020-04-21',status: 'Approved',delete: <a>Delete</a>,},
        {key: 13,filename: 'File name will appear here',type: 'Billing file',post: '2020-04-21',status: 'Uploaded',delete: <a>Delete</a>,},
        {key: 14,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 15,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 16,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 17,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 18,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Approved',delete: <a>Delete</a>,},
        {key: 19,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 20,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,},
        {key: 21,filename: 'File name will appear here',type: 'Agent Service fee file',post: '2020-04-21',status: 'Pending',delete: <a>Delete</a>,}
    ];

    interface formDataProps {
        search_by: string;
        file: string
    }
    const sampleFormData: formDataProps = {
        search_by: "",
        file: ""
    }
    const [currentTab, setCurrentTab] = useState<string>('All');
    const [showSuccessModal1, setShowSuccessModal1] = useState<boolean>(false);
    // const [searchData, setSearchData] = useState<searchJournalProps>(formData);
    const [allfileData, setAllfileData] = useState<any[]>(dummyData);
    const [filesToShow, setFilesToShow] = useState<any[]>(dummyData);
    const [filterCriteria, setFilterCriteria] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<formDataProps>(sampleFormData);

    const handleUpdate = (e: eventProps) => {
        const { name, value } = e.target;
        const newFromData = { ...formData };
        // @ts-ignore
        newFromData[name] = value;
        setFormData(newFromData);
    }

    const AccountTab = (tab: string,status:string='All') => {
        setCurrentTab(tab);
        filterFiles(undefined,status)

    }
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

    const filterFiles = (e: any,status:string='') => {
        let statusVal = "";
        if(e){
            const {value} = e.target;
            statusVal = value;
        } else {
            statusVal = status;
        }
        
        console.log('filter value', statusVal);
        const tempFiles = [...allfileData];
        const filteredFiles = tempFiles.filter(file => statusVal == 'All' ? true : file.status == statusVal);
        setFilesToShow(filteredFiles);
    }
    const filterFiles1 = (e: any) => {
        const {value} = e.target;
        console.log('filter value', value);
        const tempFiles = [...allfileData];
        const filteredFiles = tempFiles.filter(file => value == 'All' ? true : file.type === value);
        setFilesToShow(filteredFiles);
    }

    const { Dragger } = Upload;
    const props = {
        name: 'file',
        action: 'https://general.sonic.dev.coherent.com.hk/general/v2/s3/upload',
        
        onChange(info: { file: { name?: any; status?: any; }; fileList: any; }) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log("Uploading in Progress",info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e: { dataTransfer: { files: any; }; }) {
            console.log('Dropped files:', e.dataTransfer.files);
        },

    };


    const Data1 = filesToShow;

    const allUploadTableColumn = [
        {
            title: 'File Name',
            dataIndex: 'filename',
            key: 'filename',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Post Date',
            dataIndex: 'post',
            key: 'post',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            // @ts-ignore
            render: status => (
              <>
            
                    <Tag color={status == 'Pending'? '#A0A0A0':status =='Uploaded' ? '#24C4CC':'#27AE60'}
                    key={status} id="rect">
                      {status}
                    </Tag>
                 
              
                {/* {status.map(status => {
                  let color = status.length > 7 ?'#27AE60':'#FFA800';
                  return (
                    <Tag color={color} key={status}>
                      {status}
                    </Tag>
                  );
                })} */}
              </>
            ),
          },
        
          {
            title: '',
            dataIndex: 'delete',
            //@ts-ignore
            render: currentTab =>(
                <>
                {/* {
                    currentTab !== 'Awaiting Approval' && (
                        <div><a href="">Delete</a></div>
                        )
                } */}
                    {currentTab === 'Awaiting Approval'}{
                        <div>Approve Reject</div>
                    }
                
                </>

            )
          }
    ];
    const showModal = () => {
        setShowSuccessModal(true)
    }
    const closeModal = () => {
        setShowSuccessModal(false)
    }

    // useEffect(() => {
    //     //@ts-ignore
    //     window.mjDetails = undefined;
    //     //@ts-ignore
    //     window.redirectFrom = 'AllJournal';
    //     const startDate = new Date();
    //     startDate.setMonth(startDate.getMonth() - 2);
    //     let searchStr = "?search_by=PostingDate&value=";
    //     searchStr += `${parseDate(startDate)}_${parseDate(today)}&order_by=JournalID_DESC&limit=50`;
    //     getManualJournal(searchStr);
    // }, [])

    // const goToMJDetails = (rec: journalProps) => {
    //     //@ts-ignore
    //     window.mjDetails = rec;
    //     navigate('/ViewJournalReceipt');
    //     //console.log(rec, index, event.target)
    // };

    // const closeModal = () => {
    //     setShowSuccessModal(false);
    // }

    // const filterJournalList = (tab: string, data: journalProps[] = []) => {
    //     const tabName = tab === 'Posted' ? 'All' : tab;
    //     setCurrentTab(tabName);
    //     const dataToFilter = (data.length > 0) ? data : mjData;
    //     const filterdJournal = dataToFilter.filter(mj => {
    //         switch (tab) {
    //             case 'Drafts':
    //                 return mj.Status === 'Draft' || mj.Status === "";
    //             case 'Awaiting_Approval':
    //                 return mj.Status === 'Pending';
    //             case 'Posted':
    //                 return mj.Status === 'Posted';
    //             default:
    //                 return true;
    //         }
    //     }).map(mj => {
    //         mj.UploadDate = showDate(mj.UploadDate);
    //         mj.PostingDate = showDate(mj.PostingDate);
    //         return mj;
    //     });
    //     setFilteredMjData(filterdJournal);
    // }


    // const handleUpdate = (e: eventProps) => {
    //     const { name, value } = e.target;
    //     const newFromData = { ...searchData };
    //     // @ts-ignore
    //     newFromData[name] = value;
    //     setSearchData(newFromData);
    //     if (name === 'allJournal_filter') {
    //         filterJournalList(value);
    //     }
    // }

    // const clearFilter = () => {
    //     setSearchData(formData);
    // }

    // const searchManualJournal = () => {
    //     let searchStr = "?search_by=";
    //     if (searchData.search_value) {
    //         searchStr += `${searchData.search_by}&value=${searchData.search_value}`;
    //     } else {
    //         if (searchData.from_date && searchData.to_date) {
    //             searchStr += `PostingDate&value=${searchData.from_date}_${searchData.to_date}&order_by=JournalID_DESC&limit=40`;
    //         } else {
    //             alert('Please enter the search criteria properly.');
    //         }
    //     }
    //     getManualJournal(searchStr);
    // }

    // const getManualJournal = async (searchStr: string) => {
    //     if (searchStr) {
    //         setIsLoading(true);
    //         try {
    //             await fetch(`${endPoint.getMJ}${searchStr}`, {
    //                 method: 'GET',
    //                 headers: {
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/json',
    //                 }
    //             })
    //                 .then((response: any) => {
    //                     const statusCode = response.status;
    //                     const data = response.json();
    //                     return Promise.all([statusCode, data]);
    //                 })
    //                 .then(([statusCode, data]) => {
    //                     setIsLoading(false);
    //                     if (statusCode === 200 && data.length > 0) {
    //                         setMjData(data);
    //                         filterJournalList('All', data);
    //                     }
    //                 });
    //         } catch (exception) {
    //             setIsLoading(false);
    //             console.log(exception);
    //         }
    //     } else {
    //         console.log('Search value is empty');
    //     }
    // }

    console.log("This is form data before render", formData)

    return (
        <div id="all-upload-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-nolink">File Upload</li>
                        </ul>
                    </div>
                    <div className="m-ap-btn plush" onClick={showModal}>Upload File</div>
                </div>

                <div className="m-ap-tabs m-ap-m-b-15">
                    <div className="m-ap-row">
                        <label className={`receipt-tab ${(currentTab === 'All') ? 'tab-active' : ''}`} onClick={() => AccountTab('All')}>
                            All
                        </label>
                        <label className={`receipt-tab ${(currentTab === 'Awaiting Approval') ? 'tab-active' : ''}`} onClick={() => AccountTab('Awaiting Approval','Pending')}>
                            Awaiting Approval
                        </label>
                    </div>
                </div>

                <div className="m-ap-search m-ap-m-b-15 pad-l-r-30">
                    <div className="m-ap-row search-elements">
                        <div className="m-ap-input-f m-ap-dropdown">
                            <label >Search Document </label>
                            <select name="search_by" id="search_by">
                                <option value="FileName">File Name</option>
                                {/* <option value="TPA Claim Service fee file">TPA Claim Service fee file</option>
                                <option value="TCBBf">TPA Claim Benefit Billing file</option>
                                <option value="ASff">Agent Service fee file</option>
                                <option value="BF">Billing file</option> */}
                            </select>
                        </div>
                        <div className="m-ap-input-f m-ap-search-f">
                            <label>&nbsp;</label>
                            <input type="text" placeholder="Begin your search"
                                id="search_value" name="search_value" />
                        </div>
                        <div className="m-ap-input-f m-ap-date">
                            <label>Date</label>
                            <input type="date" placeholder="Begin your search"
                                id="from_date" name="from_date" />

                        </div>
                        <div className="m-ap-input-f m-ap-to">
                            <label>To</label>
                        </div>
                        <div className="m-ap-input-f m-ap-date">
                            <label>&nbsp;</label>
                            <input type="date" placeholder="Begin your search"
                                id="to_date" name="to_date" />
                        </div>
                        <div>
                            <button id="receipt-search">Search</button>
                            <button id="clear-filter">Clear Filters</button>
                        </div>
                        {/* <div className="m-ap-input-f m-ap-flex m-ap-action">
                            <div className="m-ap-btn m-ap-search m-ap-blue" id="receipt-search" onClick={searchManualJournal}>Search</div>
                            <div className="m-ap-btn" id="clear-filter" onClick={clearFilter}>Clear Filters</div>
                        </div> */}
                    </div>
                </div>
                <div className="m-ap-filter m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15 pad-l-r-30">
                    <div className="m-ap-title">{currentTab} File ({Data1.length})</div>
                    <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                        <div className="m-ap-filter-text filter-status">Filter by : Type</div>
                        <div className="m-ap-input-f">
                            <select name="allUpload_filter" id="allUpload_filter" onChange={e => filterFiles1(e)}>
                                <option value="All">All</option>
                                <option value="TPA Claim Service fee file">TPA Claim Service fee file</option>
                                <option value="TPA Claim Benefit Billing file">TPA Claim Benefit Billing file</option>
                                <option value="Agent Service fee file">Agent Service fee file</option>
                                <option value="Billing file">Billing file</option>
                            </select>
                        </div>
                    
                        <div className={`m-ap-filter-text filter-status ${(currentTab =='Awaiting Approval' ? "hide-fil":"")}`}>Status</div>
                        <div className={`m-ap-input-f ${(currentTab == 'Awaiting Approval'? "hide-fil":"")}`}>
                            <select name="allUpload_filter" id="allUpload_filter" onChange={e => filterFiles(e)}>
                                <option value="All">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Approved">Approved</option>
                                {
                                    currentTab == 'Awaiting Approval' && (
                                        <div>approve reject</div>
                                    )
                                }
                                {
                                    currentTab != 'Awaiting Approval' && (
                                        <div>current implmentation</div>
                                    )
                                }
                                <option value="Uploaded">Uploaded</option>
                            </select>
                        </div>
                    
                    </div>
                </div>

                <div className="pad-l-r-30">
                    <div className="m-ap-table-box">
                        <div className="m-ap-table">

                            <Table
                                columns={allUploadTableColumn}
                                dataSource={Data1}
                                pagination={{ pageSize: 10 }}

                            />
                        </div>
                    </div>
                </div>
                {
                    showSuccessModal && (
                        <div className="modal1" id="myModal">
                            <div className="modal-content1">
                                <div className="col-md-12 mb-4">
                                    <span>
                                        Upload New File<br></br>
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <div className={`m-ap-input-f m-ap-dropdown`}>
                                        <label>Select File Type <span id="drop">*</span></label>
                                        <br></br>
                                        <select className="col-md-5 mb-3" name="search_by" id="search_by" onChange={(e) => handleUpdate(e)}>
                                            <option value="">Search By</option>
                                            <option value="TCSff">TPA Claim Service fee file</option>
                                            <option value="TCBBf">TPA Claim Benefit Billing file</option>
                                            <option value="ASff">Agent Service fee file</option>
                                            <option value="BF">Billing file</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={`col-md-12 mb-2 receipt-success ${(!formData.search_by?"str-disable":"")}`}>
                                        <Dragger {...props} maxCount={1} >
                                            <p className="ant-upload-drag-icon">
                                            </p>
                                            <p className="ant-upload-text">Drop or Drag File Here <span id="or">or</span></p>
                                            <p className="ant-upload-hint">Click to upload a New File to upload document</p>
                                            <Button>Upload New File</Button>
                                        </Dragger>
                                </div>
                                <div className="col-md-12 mb-2 download-receipt-container">
                                    <Button id="download-receipt-close" onClick={closeModal}>Upload</Button>
                                    <button id="download-receipt-close" onClick={closeModal}>Cancel</button>
                                </div>
                                <br /><br />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default AllUpload;