import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PolicyConfirmation.css';
//import image from './image.svg';
//import {ReactComponent as iImage} from './logo.svg';
import iImage from './image.jpeg';
import { GroupPolicyProps } from './GroupPolicyInterfaces';
// import { initialPolicyData } from './PrepareData';

const PolicyConfirmation = () => {

    const navigateBack = (e: any) => {
        e.preventDefault();
        navigate('/addMember');
    };

    const [policyDetails, setPolicyDetails] = useState<GroupPolicyProps>(Object);

    const showPolicyDetails = () => {
        // @ts-ignore
        const ttt = { ...window.GroupPolicy.CurrentPolicy };
        setPolicyDetails(ttt);
        // @ts-ignore
        console.log(window.GroupPolicy.CurrentPolicy)
        setTimeout(() => {
            console.log('this is confir', policyDetails);
        }, 1000)

    }

    useEffect(() => {
        showPolicyDetails();
    }, []);

    const isoToDate = (iDate: string) => {
        const date = new Date(iDate);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();

        if (month < 10) {
            //@ts-ignore
            month = `0${month}`;
        }
        return `${year}-${month}-${dt}`;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <div id="confirmation-container">
            <div className="all">
                <div className="head">
                    <button onClick={(e) => navigateBack(e)} className="back">&#8592;</button>&nbsp;&nbsp;
                    <button className="gear">&#9881;</button>&nbsp;&nbsp;
                    <div >
                        <span>
                            <img src={iImage} alt="Agent" />
                            <h2 className="heading">{(policyDetails.EntityInfo && policyDetails.EntityInfo.EntityName) || '-'}</h2>
                            <span className="policy-id">{policyDetails.MasterPolicyID || '-'}</span> <br /> <br />
                            <button type="button">{policyDetails.UWStatus}</button><br />
                        </span>
                    </div>
                </div>
                <div className="table-container policy-detail-table">
                    <table>
                        <tbody>
                            <tr>
                                <td>Registration Number</td>
                                <td>{(policyDetails.EntityInfo && policyDetails.EntityInfo.IncNumber) || '-'}</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Company Type / FAO</td>
                                <td>{(policyDetails.EntityInfo && policyDetails.EntityInfo.FAO) || '-'}</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Company Address</td>
                                <td>{
                                    policyDetails.EntityInfo && policyDetails.EntityInfo.Contact && (
                                        `${policyDetails.EntityInfo.Contact.Add.Add1}, ${policyDetails.EntityInfo.Contact.Add.Add2}`
                                    )
                                }</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{
                                    policyDetails.EntityInfo && policyDetails.EntityInfo.Contact && (
                                        `${policyDetails.EntityInfo.Contact.Phone}`
                                    )
                                }</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Industry</td>
                                {/* <td>0 <u>Upload Member File</u></td> */}
                                <td>{
                                    policyDetails.EntityInfo && (
                                        `${policyDetails.EntityInfo.Industry}`
                                    )
                                }</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Representative</td>
                                <td>
                                    {
                                        policyDetails.Contacts && policyDetails.Contacts.length && (
                                            `${policyDetails.Contacts[0].FirstName}, ${policyDetails.Contacts[0].LastName}`
                                        )
                                    }
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Representative Email</td>
                                <td>{
                                    policyDetails.EntityInfo && policyDetails.EntityInfo.Contact && (
                                        `${policyDetails.EntityInfo.Contact.Email}, ${policyDetails.EntityInfo.Contact.Email}`
                                    )
                                }</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-container policy-detail-table">
                    <table>
                        <tbody>
                            <tr>
                                <td>Total Members</td>
                                <td>{policyDetails.Members && (
                                    Object.keys(policyDetails.Members).length
                                )
                                }</td>
                                <td className="member-file">
                                    <span>
                                        Add Member File
                                </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Total Premium</td>
                                <td>{
                                    policyDetails.PremiumTotals && formatter.format(policyDetails.PremiumTotals.ModalPremium)
                                }</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Last Update</td>
                                <td>{policyDetails.StartDate}</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-title">
                    Benefits Details -
                </div>
                <div className="table-container benefits-table">
                    <table>
                        <thead>
                            <tr>
                                <th className="w-15">Title</th>
                                <th className="w-15">Description</th>
                                <th className="w-10">Plan Type</th>
                                <th className="w-15 center-header">
                                    <span className="">Premium /</span><br />
                                    Discounted Premium
                                </th>
                                <th className="center-header">Benefits</th>
                            </tr>
                        </thead>
                        <tbody>
                            {policyDetails.Plans && Object.keys(policyDetails.Plans).map((plan, index) => (
                                <tr key={index}>
                                    <td>{plan}</td>
                                    <td>
                                        {
                                            //@ts-ignore
                                            policyDetails.Plans[plan].Description
                                        }
                                    </td>
                                    <td>
                                        {
                                            //@ts-ignore
                                            policyDetails.Plans[plan].PlanType
                                        }
                                    </td>

                                    {
                                        //@ts-ignore
                                        (policyDetails.Plans[plan].DiscountedPremium && policyDetails.Plans[plan].DiscountedPremium < policyDetails.Plans[plan].Premium) && (
                                            <td className="center-header">
                                                <span className="striked-content">
                                                    {
                                                        //@ts-ignore
                                                        `${formatter.format(policyDetails.Plans[plan].Premium)}`
                                                    }
                                                </span> <br />
                                                {
                                                    //@ts-ignore
                                                    `${formatter.format(policyDetails.Plans[plan].DiscountedPremium)}`
                                                }
                                            </td>
                                        )
                                    }
                                    {
                                        //@ts-ignore
                                        (!policyDetails.Plans[plan].DiscountedPremium || policyDetails.Plans[plan].DiscountedPremium >= policyDetails.Plans[plan].Premium) && (
                                            <td className="center-header">
                                                    {
                                                        //@ts-ignore
                                                        `${formatter.format(policyDetails.Plans[plan].Premium)}`
                                                    }
                                            </td>
                                        )
                                    }
                                    <td>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th>Included</th>
                                                    <th>Bundled</th>
                                                    <th>Sum Insured</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    //@ts-ignore
                                                    Object.keys(policyDetails.Plans[plan].Benefits).map((benefit, index) => (
                                                        <tr>
                                                            <td>
                                                                {
                                                                    //@ts-ignore
                                                                    benefit
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    //@ts-ignore
                                                                    policyDetails.Plans[plan].Benefits[benefit].Description
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    //@ts-ignore
                                                                    (policyDetails.Plans[plan].Benefits[benefit].Included) && (
                                                                        "Yes"
                                                                    )
                                                                }
                                                                {
                                                                    //@ts-ignore
                                                                    (!policyDetails.Plans[plan].Benefits[benefit].Included) && (
                                                                        "No"
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    //@ts-ignore
                                                                    (policyDetails.Plans[plan].Benefits[benefit].Bundled) && (
                                                                        "Yes"
                                                                    )
                                                                }
                                                                {
                                                                    //@ts-ignore
                                                                    (!policyDetails.Plans[plan].Benefits[benefit].Bundled) && (
                                                                        "No"
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    //@ts-ignore
                                                                    policyDetails.Plans[plan].Benefits[benefit].SumInsured
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>

                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                </div>

                <div className="table-title">
                    Memebers List -
                </div>

                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Member ID</th>
                                <th>Member Type</th>
                                <th>Plan</th>
                                <th>Name(Gender)</th>
                                <th>Date of Birth</th>
                                <th>Email</th>
                                <th>Nationality</th>

                            </tr>
                        </thead>
                        <tbody>
                            {policyDetails.Members && Object.keys(policyDetails.Members).map((member, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{
                                        //@ts-ignore
                                        policyDetails.Members[member].MemberID
                                    }
                                    </td>
                                    <td>
                                        {
                                            //@ts-ignore
                                            policyDetails.Members[member].MemberType
                                        }
                                    </td>
                                    <td>
                                        {
                                            //@ts-ignore
                                            policyDetails.Members[member].Plan
                                        }
                                    </td>
                                    <td>
                                        {
                                            //@ts-ignore
                                            `${policyDetails.Members[member].Member.FirstName} ${policyDetails.Members[member].Member.LastName}(${policyDetails.Members[member].Member.Gender})`
                                        }</td>
                                    <td>
                                        {
                                            //@ts-ignore
                                            isoToDate(policyDetails.Members[member].Member.Dob)
                                        }
                                    </td>
                                    <td>{
                                        //@ts-ignore
                                        policyDetails.Members[member].Member.Contact.Email
                                    }</td>
                                    <td>
                                        {
                                            //@ts-ignore
                                            policyDetails.Members[member].Member.Nationality
                                        }
                                    </td>
                                </tr>
                            ))
                            }

                        </tbody>
                    </table>
                </div>

                {/* <div className="last">
                    <button className="next">Plan Benifits  <span >&#8594;</span></button>
                </div> */}
            </div>
        </div>
    )
}

export default PolicyConfirmation;
