import React, { useEffect, useState } from 'react';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import { reportProps, reportListProps, eventProps, searchReportProps, } from '../interfaces';
import { reportDates, sampleReportData, reportDateFormat } from '../ReportController';
import Loader from '../../Components/Loader/Loader.componet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Reports.styles.scss';
import { render } from '@testing-library/react';
//import Loader from "react-loader-spinner";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { DatePicker, TimePicker, Select, Space } from 'antd';
// const { RangePicker } = DatePicker;



const Reports = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [accordianSectionActive, setAccordianSectionActive] = useState<number>(-1);
    const [downloadBox, setDownloadBox] = useState<boolean>(false);
    const [reportCategoryList, setReportCategoryList] = useState<string[]>([]);
    const [reportList, setReportList] = useState<object>({});
    const [reportToDownload, setReportToDownload] = useState<reportProps>(sampleReportData);
    const [downloadReportTitle, setDownloadReportTitle] = useState<string>("");
    const [searchData, setSearchData] = useState<searchReportProps>(reportDates);


    useEffect(() => {

        getReportListFromPF();
    }, []);



    const handleUpdate = (e: eventProps) => {
        const { name, value } = e.target;

        const newReportData = { ...searchData };
        console.log(e.target.checked);
        if (name === 'has_old_unpaid') {
            // @ts-ignore
            newReportData[name] = e.target.checked;
        } else {
            // @ts-ignore
            newReportData[name] = value;
        }

        setSearchData(newReportData);

    }

    const getReportListFromPF = async () => {
        try {
            const initData = {
                "Inputs": {},
                "TransactionDate": "2021-06-02T06:29:25.649Z",
                "RequestedServiceCategory": "Reports"
            }
            await fetch(`${endPoint.getReportFromPF}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'SecretKey': '762f5692-5a8b-4f8e-a09e-a27a277c0c56'
                },
                body: JSON.stringify(initData)
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200 && data.data.Outputs["Reports.ReportData"]) {
                        setReportData(data.data.Outputs["Reports.ReportData"]);
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    const setReportData = (reportList: reportProps[]) => {
        const reportArr: string[] = [];
        const allReports = {};
        reportList.forEach(report => {
            if (!reportArr.includes(report.Function)) {
                reportArr.push(report.Function);
                //@ts-ignore
                allReports[report.Function] = [];
            }
            //@ts-ignore
            allReports[report.Function].push(report);
        });
        setReportCategoryList(reportArr);
        setReportList(allReports);
    }



    const search = () => {
        const download = !downloadBox;
        setDownloadBox(download);
    }

    const downloadReport = () => {
        const report: reportProps = { ...reportToDownload };
        let downloadUrl = report.API_Endpoint;

        if (downloadUrl && downloadUrl !== "null") {
            // let searchStr = `${searchData.start_date}/${searchData.end_date}/${searchData.asat_date}`; // dd-mm=yyyy
            console.log(searchData.start_date, searchData.end_date);
            downloadUrl = downloadUrl.replace('{host}/', '');
            downloadUrl = downloadUrl.replace('{startdate}', reportDateFormat(searchData.start_date));
            downloadUrl = downloadUrl.replace('{enddate}', reportDateFormat(searchData.end_date));
            downloadUrl = downloadUrl.replace('{Asatdate}', reportDateFormat(searchData.asat_date));
            downloadUrl = downloadUrl.replace('{HasOldUndpaid}', `${searchData.has_old_unpaid}`);
            console.log('this is download Url', downloadUrl);
            //https://general.sonic.dev.coherent.com.hk/general/v2/report/UWReport/{startdate}/{endate}/{Asatdate}
            const link = document.createElement('a');
            link.setAttribute('href', `${downloadUrl}`);
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            // setIsLoading(true);
            // try {
            //     fetch(`${downloadUrl}`, {
            //         method: 'GET',
            //         headers: {
            //             'Content-Type': 'application/json',
            //         }
            //     })
            //         .then((response: any) => {
            //             const statusCode = response.status;
            //             const data = response.blob();
            //             return Promise.all([statusCode, data]);
            //         })
            //         .then(([statusCode, data]) => {
            //             setIsLoading(false);
            //             if (statusCode === 200) {
            //                 //console.log(data.type);
            //                 //const type = data.type.split('/')[1];
            //                 const type = report.Type.toLowerCase();
            //                 const href = window.URL.createObjectURL(data);
            //                 const link = document.createElement('a');
            //                 link.href = href;
            //                 link.setAttribute('download', `${report.Function}${report.Report_Desc.replace(/ /g, '')}${report.Report_ID}.${type}`);
            //                 document.body.appendChild(link);
            //                 link.click();
            //             }
            //         });
            // } catch (exception) {
            //     setIsLoading(false);
            //     console.log(exception);
            // }
        } else {
            alert("Download URL is blank");
        }
    }

    const reportclick = (selectedReport: reportProps, reportHeading: string) => {
        setReportToDownload(selectedReport);
        setDownloadReportTitle(reportHeading);
        const report = !showSuccessModal;
        setShowSuccessModal(report);
    }



    const closeModal = () => {
        clearFilter();
        setShowSuccessModal(false);

    }
    const clearFilter = () => {
        setDownloadBox(false);
        setSearchData(reportDates);
    }

    console.log('search data', searchData);
    return (
        <div id="create-new-report-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Reports</a></li>
                            <li className="b-slace">/</li>
                        </ul>
                    </div>
                </div>

                {
                    reportCategoryList.length > 0 && reportCategoryList.map((report, index) => (
                        <div className={`m-ap-accordion m-ap-m-b-15 ${accordianSectionActive === index ? 'm-ap-active' : ''}`}>
                            <div className="m-ap-ac-head" onClick={() => setAccordianSectionActive(index)}>
                                <div className="m-ap-sub-title" >{report}</div>
                                <div className="m-ap-arrow" ></div>
                            </div>
                            {
                                accordianSectionActive === index && (
                                    <div className="m-ap-ac-content">
                                        <div className="m-ap-table">
                                            <table>
                                                <tbody>
                                                    {
                                                        //@ts-ignore
                                                        reportList[report] && reportList[report].length > 0 && reportList[report].map(rp => (
                                                            <tr>
                                                                <td onClick={() => reportclick(rp, report)}><a>{rp.Report_Desc}</a></td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                        </div>
                    ))
                }
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content">
                            <div className="download-receipt-title">
                                <span>
                                    {downloadReportTitle} - {reportToDownload.Report_Desc}
                                </span>
                            </div>
                            {
                                reportToDownload.API_Endpoint && reportToDownload.API_Endpoint.includes("{startdate}") && reportToDownload.API_Endpoint.includes("{enddate}") ? (
                                    <div className="">
                                        <label id="label1">Start Date</label>
                                        <label id="label1">End Date</label>
                                        {
                                            reportToDownload.API_Endpoint.includes("{Asatdate}") && (
                                                <label id="label1">As At Date</label>
                                            )}
                                        <br />
                                        <div className="">

                                            <input type="date" name="start_date" value={searchData.start_date} onChange={(e) => handleUpdate(e)} onBlur={(e) => handleUpdate(e)} required />
                                            <input type="date" name="end_date" value={searchData.end_date} min={searchData.start_date} onChange={(e) => handleUpdate(e)} onBlur={(e) => handleUpdate(e)} required />
                                            {
                                                reportToDownload.API_Endpoint.includes("{Asatdate}") && (
                                                    <input type="date" name="asat_date" value={searchData.asat_date} onChange={(e) => handleUpdate(e)} required />

                                                )
                                            }
                                            {
                                                reportToDownload.API_Endpoint.includes("{HasOldUndpaid}") && (
                                                    <div>
                                                        <input type="checkbox" name="has_old_unpaid" value={searchData.has_old_unpaid} checked={searchData.has_old_unpaid}
                                                            onChange={(e) => handleUpdate(e)} required />
                                                        <label htmlFor="has_old_unpaid">Unpaid</label>
                                                    </div>
                                                )
                                            }
                                            <button type="submit" id="receipt-search" disabled={!searchData.start_date || !searchData.end_date} onClick={search}>Generate</button>
                                            <button id="clear-filter" onClick={clearFilter}>Clear filter</button>

                                        </div>

                                    </div>
                                ) : (
                                    <div className="download-box">
                                        <p id="title">{downloadReportTitle}</p>
                                        <p id="text1">{reportToDownload.Report_Desc}</p>
                                        <button id="download-report" onClick={downloadReport}>Download Report as {reportToDownload.Type}</button>
                                    </div>
                                )
                            }

                            {
                                downloadBox && (
                                    <div className="download-box">
                                        <p id="title">{downloadReportTitle}</p>
                                        <p id="text1">{reportToDownload.Report_Desc}</p>
                                        {
                                            reportToDownload.API_Endpoint && reportToDownload.API_Endpoint.includes("{startdate}") && reportToDownload.API_Endpoint.includes("{enddate}") && (
                                                <p id="text2">for {searchData.start_date} to {searchData.end_date}</p>
                                            )}

                                        <button id="download-report" onClick={downloadReport}>Download Report as {reportToDownload.Type}</button>
                                    </div>
                                )}


                            <div className="download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )

            }
        </div >
    )

}

export default Reports;