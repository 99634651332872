import React, { useState }  from 'react';
import { navigate } from 'raviger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AddBenefits.css';
import { BenefitsList } from './BenefitList';

const AddBenefits = () => {

    const BenefitsJSON = JSON.parse(JSON.stringify(BenefitsList));

    const [benefits, setBenefits] = useState<object>(BenefitsJSON);

    const navigateBack = (e: any) => {
        e.preventDefault();
        navigate('/newPolicy');
    };

    const gotoAddMember = () => {
        navigate('/addMember');
    }

    const handlePlanChange = (e: any, benefit: string, plan: string) => {
        const { checked } = e.target;
        const newBenefit = {...benefits};
        // @ts-ignore
        newBenefit[benefit][plan] = checked;
        setBenefits(newBenefit);
    }

    return (
        <div id="benefits-container">
            <div className="all">
                <div className="page">
                    <div className="head">
                        <button onClick={(e) => navigateBack(e)} className="back">&#8592;</button>&nbsp;&nbsp;
                        <h1 className="heading">Add New Policy</h1>
                    </div>
                    <div>
                        <h3 className="sub-heading">Select Benefits</h3>
                        <button type="button">+ Add Plan</button>
                        <button type="button" className="benefit-button">+ Add Benefits</button>
                    </div>
                    <div className="content-container">
                        <table className="table table-striped">
                            <tbody>
                                <tr className="th-row">
                                    <th className="pad-left-th">Benifits</th>
                                    <th>Plan 1</th>
                                    <th>Plan 2</th>
                                    <th>Plan 3</th>
                                    <th>Plan 4</th>
                                    <th>Plan 5</th>
                                    <th>Plan 6</th>
                                    <th>Plan 7</th>
                                    <th>Plan 8</th>
                                </tr>
                                {
                                    Object.keys(benefits).map(key => (
                                        <tr key={key}>
                                            <td className="pad-left">{key.replace(/_/g, ' ')}</td>
                                            {
                                                //@ts-ignore
                                                Object.keys(benefits[key]).map(plan => (
                                                    //@ts-ignore
                                                    <td key={`${key}-${plan}`}><input type="checkbox" checked={benefits[key][plan]} onChange={(e) => handlePlanChange(e, key, plan)}/></td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div className="bottom">
                {/* <button type="button" className="left">+Add New Benifit</button> */}
                <button type="button" id="continue" onClick={gotoAddMember}>Continue</button>&nbsp;&nbsp;
                    <button type="button" id="skip">Skip</button>&nbsp;&nbsp;
                    {/* <a href="#" id="skip-link">Skip</a> */}
            </div>
        </div>
    )
}

export default AddBenefits;
