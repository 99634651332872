export const RequestHeader = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
}

export const getAddPolicyData = (formData) => {
    return {
        "Product": "Coherent Group Product",
        "Term": 1,
        "StartDate": "2021-01-22T09:40:36.940641599Z",
        "Status": "NewPolicy",
        "PaymentFrequency": 1,
        "PaymentMethod": "Direct",
        "UWStatus": "Pass",
        "AgentID": "AG12389",
        "Channel": "Direct",
        "EntityInfo": {
            "EntityName": formData.company_name,
            "IncDate": "2000-01-22T09:40:36.940641599Z",
            "IncNumber": formData.registration_number,
            "FAO": formData.company_type,
            "Contact": {
                "Email": formData.representative_email,
                "Phone": formData.website_url,
                "Add": {
                    "Add1": formData.add1,
                    "Add2": formData.add2,
                    "Add3": "HK Island",
                    "City": "Hong Kong",
                    "Country": "Hong Kong"
                }
            },
            "Industry": formData.company_name,
        },
        "Contacts": [{
            "FirstName": formData.representative_name,
            "LastName": formData.representative_name,
            "Dept": "Finance",
            "Role": "Head",
            "Contact": {
                "Email": formData.representative_email,
                "Phone": "+852-1233-1234",
                "Add": {
                    "Add1": formData.add1,
                    "Add2": formData.add2,
                    "Add3": "HK Island",
                    "City": "Hong Kong",
                    "Country": "Hong Kong"
                }
            }
        }],
        "Plans": {
            "Manager": {
                "Description": "Base minimum for all Employees",
                "RiskType": "Health",
                "RiskClass": "Normal",
                "PlanType": "Plan 4",
                "Premium": 30541,
                "DiscountedPremium": 30541,
                "Gst": 0,
                "Benefits": {
                    "GHS": {
                        "Description": "Hospitalisation Benefits",
                        "Included": true,
                        "Bundled": true,
                        "OverridePlan": "",
                        "SumInsured": 0,
                        "Premium": 9184
                    },
                    "SMM": {
                        "Description": "Supplementary Major Medical Benefits",
                        "Included": true,
                        "Bundled": true,
                        "OverridePlan": "",
                        "SumInsured": 0,
                        "Premium": 5510
                    },
                    "LIFE": {
                        "Description": "Life and Accident Benefits",
                        "Included": true,
                        "Bundled": true,
                        "OverridePlan": "",
                        "SumInsured": 0,
                        "Premium": 7968
                    },
                    "OP": {
                        "Description": "Outpatient Benefits",
                        "Included": true,
                        "Bundled": true,
                        "OverridePlan": "",
                        "SumInsured": 0,
                        "Premium": 7968
                    },
                    "CI": {
                        "Description": "Critical Illness",
                        "Included": true,
                        "Bundled": true,
                        "OverridePlan": "",
                        "SumInsured": 0,
                        "Premium": 4653
                    }

                },
                "RiskComm": "2021-01-22T09:40:36.940641599Z"
            },
            "Director": {
                "Description": "Management Employees",
                "RiskType": "Health",
                "RiskClass": "Normal",
                "PlanType": "Gold",
                "Premium": 120000,
                "DiscountedPremium": 1000000,
                "Gst": 0,
                "Benefits": {
                    "PC1": {
                        "Description": "In-Patient Hospitalization",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 100000,
                        "Premium": 100000
                    },
                    "PC9": {
                        "Description": "Dental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 10000,
                        "Premium": 10000
                    }
                },
                "RiskComm": "2021-01-22T09:40:36.940641599Z"
            },
            "Staff": {
                "Description": "Executive Employees",
                "RiskType": "Health",
                "RiskClass": "Normal",
                "PlanType": "Platinum",
                "Premium": 40000,
                "DiscountedPremium": 35000,
                "Gst": 0,
                "Benefits": {
                    "PC1": {
                        "Description": "In-Patient Hospitalization",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 1000000,
                        "Premium": 100000
                    },
                    "PC9": {
                        "Description": "Dental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 20000,
                        "Premium": 10000
                    },
                    "PC3": {
                        "Description": "Accidental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 2000000,
                        "Premium": 100000
                    }
                },
                "RiskComm": "2021-01-22T09:40:36.940641599Z"
            },
            "Manager's dependent": {
                "Description": "Executive Employees",
                "RiskType": "Health",
                "RiskClass": "Normal",
                "PlanType": "Platinum",
                "Premium": 40000,
                "DiscountedPremium": 35000,
                "Gst": 0,
                "Benefits": {
                    "PC1": {
                        "Description": "In-Patient Hospitalization",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 1000000,
                        "Premium": 100000
                    },
                    "PC9": {
                        "Description": "Dental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 20000,
                        "Premium": 10000
                    },
                    "PC3": {
                        "Description": "Accidental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 2000000,
                        "Premium": 100000
                    }
                },
                "RiskComm": "2021-01-22T09:40:36.940641599Z"
            },
            "Director's dependent": {
                "Description": "Executive Employees",
                "RiskType": "Health",
                "RiskClass": "Normal",
                "PlanType": "Platinum",
                "Premium": 40000,
                "DiscountedPremium": 35000,
                "Gst": 0,
                "Benefits": {
                    "PC1": {
                        "Description": "In-Patient Hospitalization",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 1000000,
                        "Premium": 100000
                    },
                    "PC9": {
                        "Description": "Dental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 20000,
                        "Premium": 10000
                    },
                    "PC3": {
                        "Description": "Accidental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 2000000,
                        "Premium": 100000
                    }
                },
                "RiskComm": "2021-01-22T09:40:36.940641599Z"
            },
            "Staff's dependent": {
                "Description": "Executive Employees",
                "RiskType": "Health",
                "RiskClass": "Normal",
                "PlanType": "Platinum",
                "Premium": 40000,
                "DiscountedPremium": 35000,
                "Gst": 0,
                "Benefits": {
                    "PC1": {
                        "Description": "In-Patient Hospitalization",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 1000000,
                        "Premium": 100000
                    },
                    "PC9": {
                        "Description": "Dental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 20000,
                        "Premium": 10000
                    },
                    "PC3": {
                        "Description": "Accidental Insurance",
                        "Included": true,
                        "Bundled": true,
                        "SumInsured": 2000000,
                        "Premium": 100000
                    }
                },
                "RiskComm": "2021-01-22T09:40:36.940641599Z"
            }

        },
        "PremiumTotals": {
            "GrossAnnualPrem": 214408,
            "NetAnnualPremium": 214408,
            "ModalPremium": 214408,
            "Gst": 0,
            "GroupDiscount": 0
        },
        "Members": [
            {
                "MemberID": "",
                "MemberType": "Member",
                "Plan": "AllEmployees",
                "Member": {
                    "FirstName": "Alpha",
                    "LastName": "Member",
                    "Gender": "M",
                    "Dob": "2000-01-22T09:40:36.940641599Z",
                    "Smoker": "N",
                    "Nationality": "Hong Kong",
                    "Id": [
                        {
                            "IDType": "HKID",
                            "IDNumber": "M12389123-9",
                            "IssueDate": "2000-01-22T09:40:36.940641599Z",
                            "ExpiryDate": null,
                            "IssuingAgency": "HK Government"
                        }
                    ]
                },
                "Start": "2021-02-22T09:40:36.940641599Z",
                "Status": "Active",
                "OptionalBenefits": null,
                "Beneficiaries": null
            }
        ],
        "Data": null,
        "Notes": null
    }
};

export const initialPolicyData = {
    Product: "",
    MasterPolicyID: "",
    PrevPolicyID: "",
    Term: 0,
    StartDate: "",
    EndDate: "",
    Status: "",
    UWStatus: "",
    EntityInfo: {
        EntityName: "",
        IncDate: "",
        IncNumber: "",
        FAO: "",
        Contact: {
            Email: "",
            Phone: "",
            FbId: "",
            Add: {
                Add1: "",
                Add2: "",
                Add3: "",
                Add4: "",
                City: "",
                Country: "",
                Pcode: ""
            }
        },
        Industry: "",
        Data: {}
    },
    Contacts: [
        {
            FirstName: "",
            LastName: "",
            Dept: "",
            Role: "",
            Contact: {
                Email: "",
                Phone: "",
                FbId: "",
                Add: {
                    Add1: "",
                    Add2: "",
                    Add3: "",
                    Add4: "",
                    City: "",
                    Country: "",
                    Pcode: "",
                }
            }
        }
    ],
    Plans: {
        Director: {
            Description: "",
            RiskType: "",
            RiskClass: "",
            PlanType: "",
            Premium: 0,
            DiscountedPremium: 0,
            Gst: 0,
            Benefits: {
                PC1: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0
                },
                PC9: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0
                }
            },
            RiskComm: "",
            RiskEnd: "",
        },
        "Directoror's dependent": {
            Description: "",
            RiskType: "",
            RiskClass: "",
            PlanType: "",
            Premium: 0,
            DiscountedPremium: 0,
            Gst: 0,
            Benefits: {
                PC1: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                PC3: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                PC9: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0
                }
            },
            RiskComm: "",
            RiskEnd: "",
        },
        Manager: {
            Description: "",
            RiskType: "",
            RiskClass: "",
            PlanType: "",
            Premium: 0,
            DiscountedPremium: 0,
            Gst: 0,
            Benefits: {
                CI: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                GHS: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                LIFE: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                OP: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                SMM: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                }
            },
            RiskComm: "",
            RiskEnd: "",
        },
        "Manager's dependent": {
            Description: "",
            RiskType: "",
            RiskClass: "",
            PlanType: "",
            Premium: 0,
            DiscountedPremium: 0,
            Gst: 0,
            Benefits: {
                PC1: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                PC3: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                PC9: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                }
            },
            RiskComm: "",
            RiskEnd: "",
        },
        Staff: {
            Description: "",
            RiskType: "",
            RiskClass: "",
            PlanType: "",
            Premium: 0,
            DiscountedPremium: 0,
            Gst: 0,
            Benefits: {
                PC1: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                PC3: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                PC9: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0
                }
            },
            RiskComm: "",
            RiskEnd: "",
        },
        "Staff's dependent": {
            Description: "",
            RiskType: "",
            RiskClass: "",
            PlanType: "",
            Premium: 0,
            DiscountedPremium: 0,
            Gst: 0,
            Benefits: {
                PC1: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                PC3: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0,
                },
                PC9: {
                    Description: "",
                    Included: true,
                    Bundled: true,
                    SumInsured: 0,
                    Premium: 0
                }
            },
            RiskComm: "",
            RiskEnd: "",
        }
    },
    AgentID: "",
    Channel: "",
    PaymentFrequency: 0,
    PaymentMethod: "",
    PremiumTotals: {
        GrossAnnualPrem: 0,
        NetAnnualPremium: 0,
        ModalPremium: 0,
        Gst: 0,
    },
    BilledPremium: {
        GrossAnnualPrem: 0,
        NetAnnualPremium: 0,
        ModalPremium: 0,
        Gst: 0,
    },
    CoinsurancePremium: {
        GrossAnnualPrem: 0,
        NetAnnualPremium: 0,
        ModalPremium: 0,
        Gst: 0,
    },
    PremiumReceipts: "",
    PremiumCollection: "",
    MemberCount: 0,
    TotalSumAtRisk: 0,
    TotalClaimedAmount: 0,
    Members: {},
    Data: {},
    Notes: {}
}
