import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import { createFormProps1, createFormProps2, eventProps } from '../interfaces';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateNewPeriod.styles.scss';
import { render } from '@testing-library/react';
import { DatePicker } from 'antd';
import moment from 'moment';




const CreateNewPeriod = () => {



    let periodData: createFormProps1 = {
        FinYear: moment(),
        FinYear1: "",
        FinPeriod: [moment(), moment()],
        FinPeriod1: ""
    }

    let periodDataRow: createFormProps2[] = [];
    for (let i = 0; i <= 11; i++) {
        periodDataRow.push({
            Period: i + 1,
            Data: "",
            FromDate: "",
            ToDate: "",
            Status: ""
        });
    }
    const [isEditFlow, setIsEditFlow] = useState<boolean>(false);
    const [periodFormValue, setPeriodFormValue] = useState<createFormProps1>(periodData);
    const [periodFormRow, setPeriodFormRow] = useState<createFormProps2[]>(periodDataRow);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [basicSectionActive, setBasicSectionActive] = useState<boolean>(true);
    const [periodSectionActive, setPeriodSectionActive] = useState<boolean>(false);
    const [noOfMonths, setNoOfMonths] = useState<number>(0);
    const [periodDates, setPeriodDates] = useState<createFormProps2[]>(periodDataRow);
    // const [disableRows, setDisableRows] = useState<number>(0);

    const { RangePicker } = DatePicker;

    useEffect(() => {
        //@ts-ignore
        const { redirectFrom, accPeriodData } = window;
        if (redirectFrom === "AllPeriods") {
            setIsEditFlow(true);
            setPeriodFormRow(accPeriodData);
        }
    }, [])
   
    const goToAllPeriod = () => {
        navigate('/AllPeriod');
    };

    const onYearChange = (date: moment.Moment | null, dateString: string) => {
        console.log("===", date);
        console.log("this is datestring", dateString)
        const newYear = { ...periodFormValue };
        // @ts-ignore
        newYear.FinYear = date;
        newYear.FinYear1 = Number(dateString);
        setPeriodFormValue(newYear);
    }


    const onRangeChange = (dates: [moment.Moment | null, moment.Moment | null], dateStrings: [string, string]) => {
        const newPeriod = { ...periodFormValue };
        // @ts-ignore
        let finanYear = newPeriod.FinYear1;
        // @ts-ignore
        newPeriod.FinPeriod = dates;
        newPeriod.FinPeriod1 = Number(dateStrings);
        console.log('these are dates', newPeriod.FinYear1);

        const startYearMonthArr = dateStrings[0].split("-");
        const endYearMonthArr = dateStrings[1].split("-");
        const diff = Number(startYearMonthArr[1]) - Number(endYearMonthArr[1])
        const diff1 = Math.abs(diff);
            if (diff1 == 1) {
            let requiredMonths = 0;
            if (startYearMonthArr[0] == endYearMonthArr[0]) {

                requiredMonths = Number(endYearMonthArr[1]) - Number(startYearMonthArr[1]) + 1;

                setPeriodFormValue(newPeriod);

                const tempFormRow = [...periodDataRow];
                for (let a = 1; a <= requiredMonths; a++) {
                    let monthVal1 = Number(startYearMonthArr[1]) - 1 + a;
                    let fromYear = startYearMonthArr[0];
                    if (monthVal1 > 12) {
                        monthVal1 = Number(monthVal1) - 12;
                        fromYear = endYearMonthArr[0];
                    }
                    const monthVal2 = `${monthVal1 > 9 ? monthVal1 : "0" + monthVal1}`;
                    tempFormRow[a - 1].FromDate = `${fromYear}-${monthVal2}-01`;
                    const lastDayOfMonth = new Date(Number(fromYear), Number(monthVal2), 28);
                    const endMonth = lastDayOfMonth.getMonth();
                    tempFormRow[a - 1].ToDate = `${lastDayOfMonth.getFullYear()}-${(endMonth < 10) ? '0' + endMonth : endMonth}-${lastDayOfMonth.getDate()}`;
                    console.log("== form row data ==", tempFormRow);
                    setPeriodFormRow(tempFormRow);

                }
            } else {
                requiredMonths = (12 - Number(startYearMonthArr[1])) + Number(endYearMonthArr[1]);
                setPeriodFormValue(newPeriod);
                const tempFormRow = [...periodDataRow];
                for (let a = 1; a <= requiredMonths + 1; a++) {

                    let monthVal1 = Number(startYearMonthArr[1]) - 1 + a;
                    let fromYear = startYearMonthArr[0];
                    if (monthVal1 > 12) {
                        monthVal1 = Number(monthVal1) - 12;
                        fromYear = endYearMonthArr[0];
                    }

                    const monthVal2 = `${monthVal1 > 9 ? monthVal1 : "0" + monthVal1}`;
                    tempFormRow[a - 1].FromDate = `${fromYear}-${monthVal2}-01`;
                    const lastDayOfMonth = new Date(Number(fromYear), Number(monthVal2) - 1, 28);
                    const endMonth = lastDayOfMonth.getMonth() + 1;
                    tempFormRow[a - 1].ToDate = `${lastDayOfMonth.getFullYear()}-${(endMonth < 10) ? '0' + endMonth : endMonth}-${lastDayOfMonth.getDate()}`;
                    console.log("== form row data ==", tempFormRow);
                    setPeriodFormRow(tempFormRow);
                }
            }
            setNoOfMonths(requiredMonths);
        }
        else {
            alert('Period range should be 12 months');
        }
    }



    const handleUpdate = (e: eventProps, index = -1) => {
        if (index < 0) {
            return;
        }
        const { value, name } = e.target;
        // const finYearValue={...periodData}
        const headerFormValue = [...periodFormRow];
        // @ts-ignore
        headerFormValue[index][name] = value;
        // setPeriodFormValue(finYearValue);
        setPeriodFormRow(headerFormValue);

    }

    console.log(periodFormValue.FinYear)

    const SubmitPeriod = () => {
        for (let i = 0; i < periodFormRow.length; i++) {
            const index = i + 1;
            const fromDate = new Date(periodFormRow[i].FromDate);
            const toDate = new Date(periodFormRow[i].ToDate);
            // apply if condition if all values of row are not empty
            let accountingPeriod;
            if (periodFormRow[i].Period && periodFormRow[i].FromDate && periodFormRow[i].ToDate) {
                accountingPeriod = {
                    "FinYear": periodFormValue.FinYear1,
                    "Period": index,
                    "Data": periodFormRow[i].Data,
                    "FromDate": fromDate.toISOString(),
                    "ToDate": toDate.toISOString(),
                    "Status": periodFormRow[i].Status
                };
                console.log('this is accounting Period', accountingPeriod);

                // whole api logic
                const url = `https://general.sonic.dev.coherent.com.hk/general/v2/finance/v1/accountingperiod`;
                fetch(url, {
                    method: "POST",
                    body: JSON.stringify(accountingPeriod),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response) => {
                        const statusCode = response.status;
                        const data = response.json();
                        return Promise.all([statusCode, data]);
                    })
                    .then(([statusCode, data]) => {
                        console.log(data);
                        if (statusCode === 200) {
                            setShowSuccessModal(true);
                        } else {
                            alert(`Error ${statusCode} - ${data.error}`)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                console.log(accountingPeriod);
            }
        }

    }

    const closeModal = () => {
        setShowSuccessModal(false);
        setPeriodFormRow(periodDataRow);
        setPeriodFormValue(periodData)
    }
    console.log('this is year', periodFormValue);
    console.log("This is array", periodFormRow);

    return (
        <div id="create-new-period-container">
            <ReceiptHeader />

            <div className="m-ap-wrap">
                <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Finance</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Accounts</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-nolink">CreateNewAccount</li>
                        </ul>
                    </div>
                </div>

                <div className="m-ap-title">{isEditFlow ? 'Modify' : 'Create New'} Accounting Period</div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${basicSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head " onClick={() => { setBasicSectionActive(!basicSectionActive); setPeriodSectionActive(false); }}>
                        <div className="m-ap-sub-title">Basic Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        basicSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Financial Year</label>
                                            <DatePicker id="FinYear" name="FinYear" value={periodFormValue.FinYear} onChange={onYearChange} picker="year" />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Financial Period</label>
                                            <RangePicker name="FinPeriod" value={periodFormValue.FinPeriod} onChange={onRangeChange} picker="month" />
                                        </div>
                                    </div>
                                </div>
                                <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                                    <div className="m-ap-btn m-ap-small-btn m-ap-blue"
                                        onClick={() => { setPeriodSectionActive(!periodSectionActive); setBasicSectionActive(false) }}>
                                        Next
                                    </div>
                                </div>
                            </div>

                        )}
                </div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${periodSectionActive ? 'm-ap-active' : ''}`} >
                    <div className="m-ap-ac-head" onClick={() => { setPeriodSectionActive(!periodSectionActive); setBasicSectionActive(!basicSectionActive) }}>
                        <div className="m-ap-sub-title">Period Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        periodSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-row-table">
                                    <div className="m-ap-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="m-ap-period">Period </th>
                                                    <th className="m-ap-description">Description </th>
                                                    <th className="m-ap-from-date">From Date </th>
                                                    <th className="m-ap-to-date">To Date </th>
                                                    <th className="m-ap-status">Status </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    periodFormRow.map((row, index) => (

                                                        <tr className={index >= noOfMonths + 1 ? "disable-inputs" : ""}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div className="m-ap-col-12 m-ap-input-f">
                                                                    <textarea placeholder="Description" id="Data" name="Data" value={row.Data} onChange={(e) => handleUpdate(e, index)} >
                                                                    </textarea>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <input type="date" placeholder="From Date" id="FromDate" name="FromDate" value={row.FromDate} onChange={(e) => handleUpdate(e, index)} />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <input type="date" placeholder="To date" id="ToDate" name="ToDate" value={row.ToDate} onChange={(e) => handleUpdate(e, index)} />
                                                                </div>
                                                            </td>
                                                            <td scope="col" >
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <select id="Status" name="Status" value={row.Status} onChange={(e) => handleUpdate(e, index)}>
                                                                        <option value="">---Select---</option>
                                                                        <option value="Open">Open</option>
                                                                        <option value="Close">Close</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                        </tr>


                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>


                <div className="m-ap-bottom">
                    <button id="create-submit" onClick={SubmitPeriod}>{isEditFlow ? 'Update Changes' : 'Create'}</button>
                    <button id="cancel" onClick={goToAllPeriod} >Cancel</button>
                </div>

                {
                    showSuccessModal && (
                        <div className="modal1" id="myModal">
                            <div className="modal-content1">
                                <div className="col-md-12 mb-2 download-receipt-title1">
                                    <span>
                                        Account Period Created
                                    </span>
                                </div>
                                <div className="col-md-12 mb-2 receipt-success">
                                    <span>
                                        Account period has been succesfully created for
                                        <b>financial year {periodFormValue.FinYear1}.</b>
                                    </span>
                                    <br />
                                </div>
                                <div className="col-md-12 mb-2 download-receipt-container">
                                    <button id="download-receipt-close" onClick={closeModal}>Close</button>
                                </div>
                                <br /><br />
                            </div>
                        </div>
                    )
                }

            </div >
        </div>
    )
}

export default CreateNewPeriod;