import React, { useState, useEffect } from 'react';

import { routes } from 'Routes';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import {
  SidebarContainer,
  LogoContainer,
  NavAction,
  AccountInfo
} from 'layout/PrivateLayout/styles';
import Navigation from 'layout/PrivateLayout/Navigation';

interface IProps {
  path: string;
}

const isLoggedIn = () => ({ attributes: { name: 'Lance Escasa' } });

const Sidebar: React.FC<IProps> = ({ path }) => {
  const [name, setName] = useState('User');
  useEffect(() => {
    const session = async () => {
      const data = await isLoggedIn();
      setName(data?.attributes?.name);
    };

    session();
  }, [setName]);

  const mainMenu = [
    {
      path: '/profile',
      icon: 'headshot',
      children: (
      <AccountInfo>
        <div className="headshot"></div>
        <span>{name}</span>
      </AccountInfo>
      )
    },
    {
      path: '/settings',
      icon: 'settings',
      title: 'Settings'
    },
    {
      path: '/logout',
      icon: 'power',
      title: 'Log Out'
    },
  ];

  return (
    <SidebarContainer
      trigger={null}
      collapsible
      collapsed={false}
      width="208"
    >
      <LogoContainer>
        <Logo height={47} />
      </LogoContainer>

      <Navigation path={path} paths={routes} isCollapse={false} />

      <NavAction>
        <Navigation
          path={path}
          paths={mainMenu}
          isCollapse={false} />
      </NavAction>
    </SidebarContainer>
  );
};

export default Sidebar;
