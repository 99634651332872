import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ReceiptHeader.css';


const ReceiptHeader = () => {
    return (
        <header>
            <div id="receipt-header-container" className="header-container">
                <div className="header-title">
                    <p>Policy 360</p>
                </div>
                <div>
                    {/* <img src="https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/tb1.png" id="top" />
                    <img src="https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/WM.png" id="WM" />
                    <img src="https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/box.png" id="top1" />
                    <img src="https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/A1.png" id="A1" />
                    <img src="https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/A.png" id="A" /> */}
                </div>
            </div>
        </header>
    )
}

export default ReceiptHeader;
