import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import { createLedgerProps, createLedgersecondform, eventProps, ledgerProps } from '../interfaces';
import { sampleLedger } from '../LedgerController';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateLedger.styles.scss';
import { render } from '@testing-library/react';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { Breadcrumb } from 'antd';
import { createMjFormProps, createMjsecondform } from 'Pages/ManualJournal/interfaces';

const CreateLedger = () => {

    let formDataOne: createLedgerProps = {
        LedgerID: "",
        ActiveFrom: "",
        ActiveTo: "",
        Type: "",
        Tag1: "",
        Tag2: "",
        Description: "",
        Status: ""
    };

    let formDataTwo: createLedgersecondform = {
        Data: ""
    };



    const [isEditFlow, setIsEditFlow] = useState<boolean>(false);
    const [basicSectionActive, setBasicSectionActive] = useState<boolean>(true);
    const [ledgerFormOne, setLedgerFormOne] = useState<createLedgerProps>(formDataOne);
    const [transSectionActive, setTransSectionActive] = useState<boolean>(false);
    const [ledgerFormTwo, setLedgerFormTwo] = useState<createLedgersecondform>(formDataTwo);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [selectedLedger, setSelectedLedger] = useState<ledgerProps>(sampleLedger);
    const [ledgerNumber, setLedgerNumber] = useState<string>("");

    useEffect (() => {
        const{ redirectFrom, ledgerDetails}=window;
        if (redirectFrom === "ViewJournal" && ledgerDetails){
            setLedgerFormOne(ledgerDetails);
            setLedgerFormTwo(ledgerDetails);
        }

    },[])

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const headerFormValue = { ...ledgerFormOne };
        // @ts-ignore
        headerFormValue[name] = value;
        setLedgerFormOne(headerFormValue);
    }
    const SubmitLedger = () => {
        const fromDate = new Date(ledgerFormOne.ActiveFrom);
        const toDate = new Date(ledgerFormOne.ActiveTo);
        const Ledger = {
            "LedgerID": ledgerFormOne.LedgerID,
            "ActiveFrom": fromDate.toISOString(),
            "ActiveTo": toDate.toISOString(),
            "Type": "Manual",
            "Tag1": ledgerFormOne.Tag1,
            "Tag2": ledgerFormOne.Tag2,
            "Description": ledgerFormOne.Description,// Transaction List Array
            "Status": ledgerFormOne.Status,
            "FinancialYear": Number(ledgerFormOne.FinancialYear),
            "FinancialPeriod": 1
            
        }
        const url = `https://general.sonic.dev.coherent.com.hk/general/v2/finance/v1/ledger`;

        fetch(url, {
            method: "POST",
            body: JSON.stringify(Ledger),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                console.log(data);
                if (statusCode === 200) {
                    setLedgerNumber(data.LedgerID);
                    setShowSuccessModal(true);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const copyLedgerNumber = () => {
        const text = ledgerNumber;
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }
    const closeModal = () => {
        setShowSuccessModal(false);
        gotoAllLedgers();
    }


    const gotoAllLedgers = () => {
        navigate('/AllLedger');
    }


    return (
        <div id="create-ledger-container">
            <ReceiptHeader />

            <div className="m-ap-wrap">
                <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Finance</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Accounts</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-nolink">CreateNewAccount</li>
                        </ul>
                    </div>
                </div>

                <div className="m-ap-title">{isEditFlow ? 'Modify' : 'Create New'} Ledger</div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${basicSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setBasicSectionActive(!basicSectionActive)}>
                        <div className="m-ap-sub-title">Basic Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        basicSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Ledger ID</label>
                                            <input type="text" placeholder="Enter Ledger ID" id="LedgerID" name="LedgerID" value={ledgerFormOne.LedgerID} onChange={(e) => handleUpdate(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Ledger Type</label>
                                            <select name="Type" id="Type" value={ledgerFormOne.Type} onChange={(e) => handleUpdate(e)}>
                                                <option value="">---Select ---</option>
                                                <option value="AccountReceivable">Account Receivable </option>
                                                <option value="Account Payable">Account Payable</option>
                                                <option value="OtherReceivable">Other Receivable </option>
                                                <option value="OtherPayable">Other Payable </option>
                                                <option value="Liability">Liability or Income </option>
                                                <option value="Expenses">Expenses</option>
                                            </select>
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Ledger Name</label>
                                            <textarea placeholder="Enter Note Here..." id="Description" name="Description" value={ledgerFormOne.Description} onChange={(e) => handleUpdate(e)}></textarea>
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Select Financial Year</label>
                                            <select name="FinancialYear" id="FinancialYear" value={ledgerFormOne.FinancialYear} onChange={(e) => handleUpdate(e)}>
                                                <option value="">Enter Financial Year e.g.2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2020">2020</option>
                                                <option value="2019">2019</option>
                                                <option value="2018">2018</option>
                                                <option value="2017">2017</option>
                                                <option value="2016">2016</option>
                                                <option value="2015">2015</option>
                                            </select>
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Active From</label>
                                            <input type="date" placeholder="YYYY/MM/DD" id="ActiveFrom" name="ActiveFrom" value={ledgerFormOne.ActiveFrom} onChange={(e) => handleUpdate(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Active To</label>
                                            <input type="date" placeholder="YYYY/MM/DD" id="ActiveTo" name="ActiveTo" value={ledgerFormOne.ActiveTo} onChange={(e) => handleUpdate(e)} />
                                        </div>
                                        
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">TagRef1</label>
                                            <input type="text" placeholder="Enter Account ID" id="Tag1" name="Tag1" value={ledgerFormOne.Tag1} onChange={(e) => handleUpdate(e)} />
                                        </div><div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">TagRef2</label>
                                            <input type="text" placeholder="Enter Account ID" id="Tag2" name="Tag2" value={ledgerFormOne.Tag2} onChange={(e) => handleUpdate(e)}/>
                                        </div>
                                        {/* <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Ledger Name</label>
                                            <textarea placeholder="Enter Note Here..." id="ledger-name" name="ledger-name"></textarea>
                                        </div> */}
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Status</label>
                                            <select name="Status" id="Status"  value={ledgerFormOne.Status} onChange={(e) => handleUpdate(e)} >
                                                <option value="">---Select ---</option>
                                                <option value="Open">Open</option>
                                                <option value="Close">Close</option>
                                            </select>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        )}

                </div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${transSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setTransSectionActive(!transSectionActive)}>
                        <div className="m-ap-sub-title" >Other Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        transSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="p-l-r-24">
                                    <div className="m-ap-col-12 m-ap-input-f">
                                        <label className="m-ap-label">Notes</label>
                                        <textarea placeholder="Enter Note Here..." id="Description" name="Description" value={ledgerFormOne.Description} onChange={(e) => handleUpdate(e)} ></textarea>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>

            <div className="m-ap-bottom">
                <button id="create-submit" onClick={SubmitLedger} >{isEditFlow ? 'Update' : 'Create'} </button>
                {/* <button id="save-draft">
                    {
                        isEditFlow ? 'Update Changes' : 'Save as Draft'
                    }
                </button> */}
                <button id="cancel" onClick={gotoAllLedgers}>Cancel</button>
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content1">
                            <div className="col-md-12 mb-2 download-receipt-title1">
                                <span>
                                    {
                                        isEditFlow ? 'Changes Updated' : 'Ledger Created'
                                    }
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                   Ledger has been created successfully!
                                </span>
                                <br />
                                <span className="entity-number">{ledgerNumber}&nbsp;</span>
                                <span className="receipt-copy" onClick={copyLedgerNumber} >Copy</span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal} >Close</button>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default CreateLedger;