import { reportProps,searchReportProps } from './interfaces';
import { BreadcrumbProps } from '../Components/interfaces';

export const AllReportsBreadcrumbItems: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: ""},
    { title: "Reports", redirectTo: ""}
];

export const sampleReportData: reportProps = {
    Function: "",
    Report_ID: "",
    Report_Desc: "",
    Freq: "",
    From_Date: "",
    To_Date: "",
    Type: "",
    API_Endpoint: "",
    API_Header: "",
    API_Params: "",
    Consolidate:"",
    Consolidate_Config: ""
}
export const reportDates: searchReportProps = {
    start_date: "",
    end_date: "",
    asat_date:"",
    has_old_unpaid:false
};
export const showDate = (date: string) => {
    if (date) {
        return date.substring(0, 10);
    }
    return date;
}
export const reportDateFormat = (date: string) => {
    let DateToParse = new Date();
    if (date) {
        DateToParse = new Date(date);
    }
    const month = ((DateToParse.getMonth() + 1) < 9) ? '0' + (DateToParse.getMonth() + 1).toString() : (DateToParse.getMonth() + 1).toString();
    return `${(DateToParse.getDate() < 10) ? '0' + DateToParse.getDate() : DateToParse.getDate()}-${month}-${DateToParse.getFullYear()}`
}