import React, { useEffect, useCallback, useState } from 'react';
import { message } from 'antd';
import { useForm, FormContext } from 'react-hook-form';
import forEach from 'lodash/forEach';

import { ModalContainer } from 'Pages/Policy/style';
import { IBasicRisk, IOption, editRisk, IRiskBody, getPolicy } from 'store/actions/PolicyAction';
import Field from 'common/Field';
import { CardInfo, CardInfoLabel } from 'common/style';
import { apiRequest, useGlobalState } from 'store';

interface IProps {
    isVisible: boolean;
    risk?: IBasicRisk;
    onOK: () => void;
    onCancel: () => void;
}

const RiskModal: React.FC<IProps> = (props) => {
    const methods = useForm();
    const [options, setOptions] = useState<Record<string, string>>({});
    const setValue = useCallback(methods.setValue, []);
    const [{ fetching }] = useGlobalState('PolicyReducer');

    useEffect(() => {
        if (props.risk) {
            if (props.risk.TravelBody?.Options) {
                setValue('Plan', props.risk.TravelBody.Plan);
                let tmp: Record<string, string> = {};
                forEach(props.risk.TravelBody.Options, ((opt: IOption) => {
                    tmp[opt.Option] = 'Y';
                }));
                setOptions(tmp);
            }
        }
    }, [props.risk, setValue])

    const submit = async (field: Record<string, any>) => {
        let Options: Array<IOption> = [];

        field.options && forEach(field.options, (Option: string) => {
            Option && Options.push({
                Option,
                Included: "Y"
            })
        });

        if (props.risk && props.risk.TravelBody) {
            let Adults = props.risk?.TravelBody.Adults;
            if (field.Name) {
                Adults = [{
                    ...props.risk?.TravelBody.Adults[0],
                    Name: field.Name
                }]
            }
            
            const data: IRiskBody = {
                ...props.risk?.TravelBody,
                Adults,
                Plan: field.Plan,
                BasePremium: 0,
                OptionalPremium: 0,
                PremiumLevy: 0,
                NetPremium: 0,
                Installment_Premium: 0,
                TotalPremium: 0,
                AppliedPremium: null,
                Risk_Comm: field.startDate,
                Risk_End: field.endDate,
                Options,
                Engine: ""
            }

            try {
                await apiRequest(editRisk(props.risk?.PolicyId, data))
                await apiRequest(getPolicy(props.risk?.PolicyId));
                message.success('Successfully edited Risk');
                props.onCancel();
            } catch (e) {
                message.error(e.message);
            }
        }
    };
    
    return (
        <FormContext {...methods}>
            <ModalContainer
                closable={false}
                maskClosable={false}
                title={`Alter Risk: ${props.risk?.RiskId}`}
                visible={props.isVisible}
                onOk={methods.handleSubmit(submit)}
                onCancel={props.onCancel}
                okButtonProps={{ loading: fetching }}
                cancelButtonProps={{ disabled: fetching }}
            >
                <form onSubmit={methods.handleSubmit(submit)}>
                    {
                        !props.risk?.RiskBody?.Adults[0]?.Name && (
                            <Field
                                name="Name"
                                label="Name"
                                required={true}
                                error={methods.errors.Name} 
                                value={props.risk?.RiskBody?.Adults[0]?.Name} />
                        )
                    }
                    <Field
                        name="Plan"
                        label="Plan Type"
                        value={props.risk?.RiskBody?.Plan}
                        placeholder="Select Plan Type"
                        error={methods.errors.plan}
                        options={[
                            { Silver: 'Silver' },
                            { Gold: 'Gold' },
                            { Platinum: 'Platinum' },
                        ]}
                        type="select"
                        required={true} />
                    <Field
                        type="dates"
                        name="startDate"
                        name2="endDate"
                        label="Start Date"
                        label2="End Date"
                        value={props.risk?.RiskBody?.Risk_Comm}
                        value2={props.risk?.RiskBody?.Risk_End}
                        error={methods.errors.startDate}
                        required={true} />
                    <CardInfo>
                        <CardInfoLabel>Benefits</CardInfoLabel>
                    </CardInfo>
                    <Field
                        type="checkbox"
                        name="options[0]"
                        value="Emergency"
                        defaultChecked={!!options['Emergency']}
                        label="Trip cancellation or Postponement" />
                    <Field
                        type="checkbox"
                        name="options[1]"
                        value="Delay"
                        defaultChecked={!!options['Delay']}
                        label="Trip cut short" />
                    <Field
                        type="checkbox"
                        name="options[2]"
                        value="Baggage"
                        defaultChecked={!!options['Baggage']}
                        label="Trip diversion (1000 for every six hours diverted overseas)" />
                    <Field
                        type="checkbox"
                        name="options[3]"
                        value="Personal"
                        defaultChecked={!!options['Personal']}
                        label="Travel delay (1000 for every six hours of delay overseas and 1000 after six hours of delay in Thailand)
"/>
                    <Field
                        value={props.risk?.RiskBody?.TotalPremium.toString()}
                        name="premium"
                        label="Premium for each Trip"
                        placeholder="0.00"
                        readonly={true} />
                </form>
            </ModalContainer>
        </FormContext>
    )
}

export default RiskModal;