export const BenefitsList = {
        Life_Insurance: {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        Total_Permanent_Disability: {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        Accidental_Death: {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        Endorsement_extended_to_coverage: {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        "In-Patient_Benifit": {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        Medical_Expenses: {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        "Out-Patient_Benifit": {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        OPD_xray_and_Laboratary_Test: {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        Dental_Care_Benifit: {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        },
        Hospital_Income_Benifit: {
            Plan1: true,
            Plan2: true,
            Plan3: true,
            Plan4: true,
            Plan5: true,
            Plan6: true,
            Plan7: true,
            Plan8: true
        }
    };