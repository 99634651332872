import React from 'react';
import { navigate } from 'raviger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AddMember.css';

const AddMember = () => {
    const navigateBack = (e: any) => {
        e.preventDefault();
        navigate('/addBenefits');
    };

    const gotoConfirmation = () => {
        navigate('/policyConfirmation')
    }

    const uploadMembersFile = (files: any) => {
        console.log('this is uploaded');
        if (files.length) {
            const fileData = new FormData();
            fileData.append("myFile", files[0]);
            const url = `https://group.sonic.dev.coherent.com.hk/newbusiness/v1/group/member/add/CCA-GRP-00129`;
            // @ts-ignore
            fetch(url, {
                method: "POST",
                body: fileData
            })
                .then((response) => response.json())
                .then((res) => {
                    console.log(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }



    return (

        <div id="add-member-container">
            <div className="border">
                <div className="page">
                    <div className="master">
                        <h2 className="heading">
                            <button onClick={(e) => navigateBack(e)} className="back">&#8592;</button>
                            Add New Policy
                        </h2>
                        <h3 className="sub-heading">Add Member</h3>
                        <span><u id="return">CSV Template</u></span>
                    </div>

                    <div className="content-container">
                        <div className="dot">
                            <div className="upload-content">
                                <div className="upload-text">
                                    <p className="text1">No Members Added Yet</p>
                                    <p>Upload a .csv file to add members</p>
                                </div>

                                <label className="custom-file-upload">
                                    <input type="file" onChange={(e) => uploadMembersFile(e.target.files)}
                                        onClick={(event: any) => {
                                            event.target.value = null
                                        }} />
                                    Upload .csv file
                            </label>
                            </div>
                            {/* <span id="center">Load a csv file to add members</span> */}
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <span id="premium-text">
                        Total Premium
                    </span>
                    <input type="text" id="premium" placeholder="$0.00" />

                    <button className="button1" onClick={gotoConfirmation}>Add policy</button>
                    <button className="button">
                        Skip
                    </button>
                    {/* <a href="" className="button-link">Skip</a> */}
                    <br />
                </div>
            </div>
        </div>
    )
}

export default AddMember;
