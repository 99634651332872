import React, { useState, useEffect } from "react";
import map from "lodash/map";

import { TableContainer } from "Pages/Policy/style";
import { InputField } from "common/Field";

interface IParameter {
  param: string;
  value: any;
}

interface IProps {
  fetching: boolean;
  func: string;
  parameters: Record<string, any>;
}

interface IModalJson {
  title: string;
  data: any;
}

const TransactionNotes: React.FC<IProps> = (props) => {
  const [parameters, setParameters] = useState<Array<IParameter>>([]);

  useEffect(() => {
    if (props.parameters) {
      setParameters(
        map(props.parameters, (value, param) => ({
          param,
          value,
        }))
      );
    } else {
      setParameters([]);
    }
  }, [props.parameters, props.func]);

  const columns = [
    {
      title: "Date",
      dataIndex: "value",
      key: "param",
      render: (value: string, record: any) => {
        const val = JSON.parse(value)
        const ls = Object.keys(val) 
        let k:string = ls[0]
        return <label>{k}</label>
      },
    },
    {
      title: "Note",
      dataIndex: "value",
      key: "value",
      render: (value: string, record: any) => {
        const val = JSON.parse(value)
        const vl = Object.values(val) 
        let v:any = vl[0]
        return <InputField name={v} label={v} value={v} readonly={true}/>
      },
    },
  ];

  return (
    <>
      <TableContainer
        columns={columns}
        rowKey="param"
        pagination={false}
        loading={props.fetching}
        dataSource={parameters}
      />
    </>
  );
};

export default TransactionNotes;
