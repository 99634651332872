import React from 'react';
import { navigate } from 'raviger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AddNewPolicy.css';
// import { BenefitsList } from './BenefitList';
import { RequestHeader, getAddPolicyData } from './PrepareData';

const AddNewPolicy = () => {
    interface formProps {
        company_name: string;
        company_type: string;
        registration_number: string;
        add1: string;
        add2: string;
        website_url: string;
        representative_name: string;
        representative_email: string;
    };

    const formData: formProps = {
        company_name: '',
        company_type: '',
        registration_number: '',
        add1: '',
        add2: '',
        website_url: '',
        representative_name: '',
        representative_email: ''
    }
    const navigateBack = (e: any) => {
        e.preventDefault();
        navigate('/agent');
    };

    // const getRandomString = (len: number, numberOnly: boolean) => {
    //     let chars = '0123456789abcdefghijklmnopqrstuvwxyz';
    //     if (numberOnly) {
    //         chars = '0123456789';
    //     }
    //     let result = '';
    //     for (var i = len; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    //     return result;
    // }

    const gotoBenefits = () => {
        console.log(formData);
        // const NewPolicy = {
        //     "id": getRandomString(6, true),
        //     "Reg": formData.registration_number,
        //     "Polno": `dcfc0be8-00ae-${getRandomString(4, false)}-${getRandomString(4, false)}-${getRandomString(12, false)}`,
        //     "Name": formData.company_name,
        //     "Email": formData.representative_email,
        //     "Status": "",
        //     "Benefits": BenefitsList
        // }
        //@ts-ignore
        // window.GroupPolicy.newPolicy = formData;
        //@ts-ignore
        // window.GroupPolicy.PolicyList.push(NewPolicy);
        const url = "https://group.sonic.dev.coherent.com.hk/newbusiness/v1/group/policy/new";
        const newPolicyData = getAddPolicyData(formData);
        //@ts-ignore
        fetch(url, {
            method: 'POST',
            headers: RequestHeader,
            body: JSON.stringify(newPolicyData)
        })
        .then((response: any) => response.json())
        .then((response: any) => {
            console.log(response);
            // @ts-ignore
            window.GroupPolicy.CurrentPolicy = response;
            navigate('/addBenefits');
        });
    };

    const handleUpdate = (e: any) => {
        const { name, value } = e.target;
        console.log('name', name, 'value', value);
        // @ts-ignore
        formData[name] = value;
    }

    return (
        <div id="add-new-container">
            <div className="all">
                <div className="page">
                    <button onClick={(e) => navigateBack(e)} className="back">&#8592;</button>&nbsp;&nbsp;
                        <h1 className="heading">Add New Policy</h1>

                    <h3 className="sub-heading">Company Details</h3>

                    <div>
                        <form>
                            <label>Company Name</label>
                            <input type="text" name="company_name" id="company_name" onChange={(e) => handleUpdate(e)} placeholder="Enter name" />

                            <label>Company Type</label>
                            <select className="form-control" name="company_type" id="company_type" onChange={(e) => handleUpdate(e)} required>
                                <option value="">Select company type</option>
                                <option value="Sole Proprietorship">Sole Proprietorship</option>
                                <option value="Limited Partnership">Limited Partnership</option>
                                <option value="Corporation">Corporation</option>
                                <option value="Limited Liability Company">Limited Liability Company</option>
                                <option value="Nonprofit Organization">Nonprofit Organization</option>
                            </select><br />

                            <label>Registration Number</label>
                            <input type="text" name="registration_number" id="registration_number" onChange={(e) => handleUpdate(e)} placeholder="Enter registration number" />

                            <label>Company Address</label>
                            <input type="text" name="add1" id="add1" onChange={(e) => handleUpdate(e)} placeholder="Address line 1 (Unit/Floor Building, Street)" />
                            <input type="text" name="add2" id="add2" onChange={(e) => handleUpdate(e)} placeholder="Address line 2 (District, City)" />

                            <label>Company Website URL</label>
                            <input type="text" name="website_url" id="website_url" onChange={(e) => handleUpdate(e)} placeholder="Enter or paste website URL" />


                        </form>
                    </div>
                    <div className="second-container">
                        <p>
                            <h3 className="sub-heading">Representative</h3>
                        </p>
                        <form>
                            <label>Company Representative</label>
                            <input type="text" name="representative_name" id="representative_name" onChange={(e) => handleUpdate(e)} placeholder="Enter first name, last name" />

                            <label>Email</label>
                            <input type="email" name="representative_email" id="representative_email" onChange={(e) => handleUpdate(e)} placeholder="Enter email" />
                        </form>
                        {/* <div>
                            <p>Lorem Ipsum</p>
                            <button type="button">Lorem</button>&nbsp;&nbsp;
                            <button type="button">Ipsum</button>&nbsp;&nbsp;
                            <button type="button">Dorem</button>&nbsp;&nbsp;
                            <button type="button">Lit</button>&nbsp;&nbsp;
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="bottom">
                <button id="continue" onClick={gotoBenefits}>Continue</button>
            </div>
        </div>
    )
}

export default AddNewPolicy;
