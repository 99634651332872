import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import {
    eventProps, accountProps, ContactDetsProps, BankDetsProps, OtherDetsProps
} from '../interfaces';
import { sampleAccount, sampleContactDets, sampleBankDets, sampleOtherDets } from '../AccountController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateNewAccount.styles.scss';
import { render } from '@testing-library/react';
import { Breadcrumb } from 'antd';



const CreateNewAccount = () => {

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [infoSection, setInfoSection] = useState<string>('Basic');
    const [accountData, setAccountData] = useState<accountProps>(sampleAccount);
    const [contactDets, setContactDets] = useState<ContactDetsProps>(sampleContactDets);
    const [bankDets, setBankDets] = useState<BankDetsProps>(sampleBankDets);
    const [otherDets, setotherDets] = useState<OtherDetsProps>(sampleOtherDets);
    const [accountNumber, setAccountNumber] = useState<string>("");

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const newAccData = { ...accountData };
        // @ts-ignore
        newAccData[name] = value;
        setAccountData(newAccData);
    }

    const handleUpdateContactDets = (e: eventProps) => {
        const { value, name } = e.target;
        const newContactData = { ...contactDets };
        // @ts-ignore
        newContactData[name] = value;
        setContactDets(newContactData);
    }

    const handleUpdateBankDets = (e: eventProps) => {
        const { value, name } = e.target;
        const newBankData = { ...bankDets };
        // @ts-ignore
        newBankData[name] = value;
        setBankDets(newBankData);
    }

    const handleUpdateOtherDets = (e: eventProps) => {
        const { value, name } = e.target;
        const newOtherData = { ...otherDets };
        // @ts-ignore
        newOtherData[name] = value;
        setotherDets(newOtherData);
    }

    const CreateAccount = () => {
        const ActiveFDate = new Date(accountData.ActiveFrom);
        const ActiveTDate = new Date(accountData.ActiveTo);
        const accountFormData = {
            "AccountID": accountData.AccountID,
            "LedgerID": accountData.LedgerID,
            "Description": accountData.Description,
            "ContactDets": JSON.stringify(contactDets),
            "ActiveFrom": ActiveFDate.toISOString(),
            "ActiveTo": ActiveTDate.toISOString(),
            "Data": `{BankDets: ${JSON.stringify(bankDets)},OtherDets: ${JSON.stringify(otherDets)}}`,
            "Status": "Open",
            "Currency": "THB",
            "DocAction": "url for action to be called"
        };

        const url = `https://general.sonic.dev.coherent.com.hk/general/v2/finance/v1/account`;

        fetch(url, {
            method: "POST",
            body: JSON.stringify(accountFormData),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                console.log(data);
                if (statusCode === 200) {
                    setAccountNumber(data.AccountID);
                    setShowSuccessModal(true);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const copyAccountNumber = () => {
        const text = accountNumber;
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy')
        textField.remove();
    }

    const closeModal = () => {
        setShowSuccessModal(false);
        gotoAllAccount();
    }

    const gotoAllAccount = () => {
        navigate('/AllAccounts')
    }

    return (
        <div id="create-new-account-container">
            <ReceiptHeader />

            <div className="m-ap-wrap">
                <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Finance</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Accounts</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-nolink">CreateNewAccount</li>
                        </ul>
                    </div>
                </div>

                <div className="m-ap-title">Create New Account</div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${infoSection === 'Basic' ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setInfoSection('Basic')}>
                        <div className="m-ap-sub-title">Basic Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        infoSection === 'Basic' && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Account ID</label>
                                            <input type="text" placeholder="Enter Account ID"
                                                name="AccountID" id="AccountID" value={accountData.AccountID}
                                                onChange={(e) => handleUpdate(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Active From</label>
                                            <input type="date" placeholder="YYYY/MM/DD"
                                                name="ActiveFrom" id="ActiveFrom" value={accountData.ActiveFrom}
                                                onChange={(e) => handleUpdate(e)} />
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Active To</label>
                                            <input type="date" placeholder="YYYY/MM/DD"
                                                name="ActiveTo" id="ActiveTo" value={accountData.ActiveTo}
                                                onChange={(e) => handleUpdate(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f ">
                                            <label className="m-ap-label">Ledger ID*</label>
                                            <input type="text"  placeholder="Enter Ledger ID"
                                                name="LedgerID" id="LedgerID" value={accountData.LedgerID}
                                                onChange={(e) => handleUpdate(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Account Name</label>
                                            <input type="text" placeholder="Enter Account ID"
                                                name="Description" id="Description" value={accountData.Description}
                                                onChange={(e) => handleUpdate(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                                    <div className="m-ap-btn m-ap-small-btn m-ap-blue" onClick={() => setInfoSection('Contact')}>Next</div>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${infoSection === 'Contact' ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setInfoSection('Contact')}>
                        <div className="m-ap-sub-title">Contact Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        infoSection === 'Contact' && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Contact Person</label>
                                            <input type="text" placeholder="Enter Account ID"
                                                name="ContactPerson" id="ContactPerson" value={contactDets.ContactPerson}
                                                onChange={(e) => handleUpdateContactDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Contact Number</label>
                                            <input type="text" placeholder="Enter Account ID"
                                                name="ContactNumber" id="ContactNumber" value={contactDets.ContactNumber}
                                                onChange={(e) => handleUpdateContactDets(e)} />
                                            {/* <select name="ContactPerson" id="ContactPerson"
                                                onChange={(e) => handleUpdateContactDets(e)} >
                                                <option>---Select---</option>
                                                <option>Option text 1</option>
                                                <option>Option text 2</option>
                                                <option>Option text 3</option>
                                            </select> */}
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Contact Person Email</label>
                                            <input type="email" placeholder="Enter email"
                                                name="ContactPersonEmail" id="ContactPersonEmail" value={contactDets.ContactPersonEmail}
                                                onChange={(e) => handleUpdateContactDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Address </label>
                                            <input type="text" placeholder="Enter address"
                                                name="Address" id="Address" value={contactDets.Address}
                                                onChange={(e) => handleUpdateContactDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Post Code*</label>
                                            <input type="text" placeholder="Enter post code"
                                                name="Pincode" id="Pincode" value={contactDets.Pincode}
                                                onChange={(e) => handleUpdateContactDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">City*</label>
                                            <select name="City" id="City" value={contactDets.City}
                                                onChange={(e) => handleUpdateContactDets(e)}>
                                                <option value="">---Select---</option>
                                                <option value="ption text 1">Option text 1</option>
                                                <option value="ption text 1">Option text 2</option>
                                                <option value="ption text 1">Option text 3</option>
                                            </select>
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Country*</label>
                                            <select name="Country" id="Country" value={contactDets.Country}
                                                onChange={(e) => handleUpdateContactDets(e)}>
                                                <option value="">---Select---</option>
                                                <option value="Option text 1">Option text 1</option>
                                                <option value="Option text 1">Option text 2</option>
                                                <option value="Option text 1">Option text 3</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                                    <div className="m-ap-btn m-ap-small-btn m-ap-blue" onClick={() => setInfoSection('Bank')}>Next</div>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className={`m-ap-accordion  m-ap-m-b-15 ${infoSection === 'Bank' ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setInfoSection('Bank')}>
                        <div className="m-ap-sub-title">Bank Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        infoSection === 'Bank' && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">A/C Number</label>
                                            <input type="text" placeholder="Enter A/C number"
                                                name="AcccountNo" id="AcccountNo" value={bankDets.AcccountNo}
                                                onChange={(e) => handleUpdateBankDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">IBAN number</label>
                                            <input type="text" placeholder="Enter IBAN number"
                                                name="IBAN" id="IBAN" value={bankDets.IBAN}
                                                onChange={(e) => handleUpdateBankDets(e)} />
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Swift Code</label>
                                            <input type="text" placeholder="Enter swift code"
                                                name="SwiftCode" id="SwiftCode" value={bankDets.SwiftCode}
                                                onChange={(e) => handleUpdateBankDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Bank</label>
                                            <input type="text" placeholder="Enter Bank"
                                                name="BankName" id="BankName" value={bankDets.BankName}
                                                onChange={(e) => handleUpdateBankDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Branch </label>
                                            <input type="text" placeholder="Enter Bank Branch"
                                                name="BranchName" id="BranchName" value={bankDets.BranchName}
                                                onChange={(e) => handleUpdateBankDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Address </label>
                                            <input type="text" placeholder="Enter Bank Branch"
                                                name="BankAddress" id="BankAddress" value={bankDets.BankAddress}
                                                onChange={(e) => handleUpdateBankDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Post Code*</label>
                                            <input type="text" placeholder="Enter post code"
                                                name="BankPincode" id="BankPincode" value={bankDets.BankPincode}
                                                onChange={(e) => handleUpdateBankDets(e)} />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">City*</label>
                                            <select name="BankCity" id="BankCity" value={bankDets.BankCity}
                                                onChange={(e) => handleUpdateBankDets(e)} >
                                                <option value="">---Select---</option>
                                                <option value="Option text 1">Option text 1</option>
                                                <option value="Option text 1">Option text 2</option>
                                                <option value="Option text 1">Option text 3</option>
                                            </select>
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Country*</label>
                                            <select name="BankCountry" id="BankCountry" value={bankDets.BankCountry}
                                                onChange={(e) => handleUpdateBankDets(e)} >
                                                <option value="">---Select---</option>
                                                <option value="option text 1">Option text 1</option>
                                                <option value="option text 1">Option text 2</option>
                                                <option value="option text 1">Option text 3</option>
                                            </select>
                                        </div>
                                        <div className="m-ap-col-12 m-ap-input-f">
                                            <label className="m-ap-label">Description</label>
                                            <textarea placeholder="enter here..." name="BankDescription" id="BankDescription"
                                                value={bankDets.BankDescription} onChange={(e) => handleUpdateBankDets(e)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                                    <div className="m-ap-btn m-ap-small-btn m-ap-blue" onClick={() => setInfoSection('Other')}>Next</div>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className={`m-ap-accordion  m-ap-m-b-15 ${infoSection === 'Other' ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setInfoSection('Other')}>
                        <div className="m-ap-sub-title">Other Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        infoSection === 'Other' && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-12 m-ap-input-f">
                                            <label className="m-ap-label">Data</label>
                                            <textarea placeholder="enter here..." name="OtherData" id="OtherData"
                                                value={otherDets.OtherData} onChange={(e) => handleUpdateOtherDets(e)}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="m-ap-bottom">
                <button id="create-submit" onClick={CreateAccount}>Create Account</button>
                <button id="cancel" onClick={gotoAllAccount}>Cancel</button>
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content1">
                            <div className="col-md-12 mb-2 download-receipt-title1">
                                <span>
                                    Account Created
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    Account has been created successfully!
                                </span>
                                <br />
                                <span className="entity-number">{accountNumber}&nbsp;</span>
                                <span className="receipt-copy" onClick={copyAccountNumber}>Copy</span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal}>Close</button>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                )
            }

        </div >
    )
}

export default CreateNewAccount;